import Axios from 'axios';
import { channelSelectedAtom } from 'atoms/channels';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { mutate } from 'swr';
import { PermissionRole } from 'types/user.types';
import { IntelbrasChannel } from 'types/intelbras.types';
import useMessages from 'hooks/useMessages';
import { useHavePermission } from 'hooks/useHavePermission';
import { formatChannel, formatChannels } from '../useChannelsApi';

export function useIntelbrasApi(customerId: number) {
  const { t } = useTranslation(['channels', 'permission', 'api_errors_messages']);
  const { enqueueSuccess, enqueueError } = useMessages();
  const insertPermission = useHavePermission(PermissionRole.INSERT_CHANNEL);
  const listFetchKey = `/v1/clients/${customerId}/channels`;
  const editPermission = useHavePermission(PermissionRole.EDIT_CHANNEL);
  const removePermission = useHavePermission(PermissionRole.REMOVE_CHANNEL);
  const setChannelSelected = useSetRecoilState(channelSelectedAtom);

  const createChannel = async (channel: IntelbrasChannel): Promise<IntelbrasChannel> => {
    if (!insertPermission) {
      enqueueError(`${t('channels:error_creating')}\n${t('permission:you_dont_have_permission')}`);
      throw new Error(t('permission:you_dont_have_permission'));
    }
    try {
      const formattedChannel = formatChannel(channel);
      const { data: response } = await Axios.post<IntelbrasChannel>(
        `/v1/clients/${customerId}/channels/p2p`,
        formattedChannel
      );
      enqueueSuccess(t('channels:channel_created'));
      mutate(listFetchKey, async (items?: IntelbrasChannel[]) => [
        items?.map((_item: IntelbrasChannel) => (_item.id === channel.id ? channel : _item)),
      ]);

      return response;
    } catch (error) {
      if (Axios.isAxiosError(error)) {
        enqueueError(
          `${t('channels:error_creating')}\n${t(
            `api_errors_messages:${error?.response?.data?.message}`
          )}`
        );
      }
      throw error;
    }
  };

  const createBatchChannels = async (channels: IntelbrasChannel[]): Promise<IntelbrasChannel[]> => {
    if (!insertPermission) {
      enqueueError(`${t('channels:error_creating')}\n${t('permission:you_dont_have_permission')}`);
      throw new Error(t('permission:you_dont_have_permission'));
    }
    try {
      const formattedChannels = formatChannels(channels);
      const { data: response } = await Axios.post<IntelbrasChannel[]>(
        `/v1/clients/${customerId}/channels/ib/p2p`,
        formattedChannels
      );
      enqueueSuccess(t('channels:channels_created'));
      mutate(listFetchKey, undefined, true);
      return response;
    } catch (error) {
      if (Axios.isAxiosError(error)) {
        enqueueError(
          `${t('channels:error_creating')}\n${t(
            `api_errors_messages:${error?.response?.data?.message}`
          )}`
        );
      }
      throw error;
    }
  };

  const updateChannel = async (channel: IntelbrasChannel) => {
    if (!editPermission) {
      enqueueError(`${t('channels:error_editing')}\n${t('permission:you_dont_have_permission')}`);
      throw new Error(t('permission:you_dont_have_permission'));
    }
    try {
      await Axios.put(`/v1/clients/${customerId}/channels/p2p/${channel.id}`, channel);
      enqueueSuccess(t('channels:channel_changed'));
      mutate(listFetchKey, async (items?: IntelbrasChannel[]) =>
        items?.map((_item: IntelbrasChannel) => (_item.id === channel.id ? channel : _item))
      );
    } catch (error) {
      if (Axios.isAxiosError(error))
        enqueueError(
          `${t('channels:error_editing')}\n${t(
            `api_errors_messages:${error?.response?.data?.message}`
          )}`
        );
      throw error;
    }
  };

  const deleteChannel = async (id: number) => {
    if (!removePermission) {
      enqueueError(`${t('error_deleting')}\n${t('permission:you_dont_have_permission')}`);
      throw new Error(t('permission:you_dont_have_permission'));
    }
    try {
      await Axios.delete(`/v1/clients/${customerId}/channels/${id}`);
      enqueueSuccess(t('channel_deleted'));
      setChannelSelected(null);
      mutate(listFetchKey, async (items?: IntelbrasChannel[]) =>
        items?.filter((_item: IntelbrasChannel) => _item.id !== id)
      );
    } catch (error) {
      if (Axios.isAxiosError(error))
        enqueueError(
          `${t('error_deleting')}\n${t(`api_errors_messages:${error?.response?.data?.message}`)}`
        );
      throw error;
    }
  };

  return {
    createChannel,
    updateChannel,
    deleteChannel,
    createBatchChannels,
  };
}
