import Axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useMessages } from 'hooks';
import { mutate } from 'swr';
import { useRecoilValue } from 'recoil';
import { channelGroupSearchTextAtom } from 'atoms/channelGroup';
import { ChannelGroup } from 'types/channelGroup.types';

export function useChannelGroupApi() {
  const { t } = useTranslation(['channel_groups', 'crud_actions', 'api_errors_messages']);
  const { enqueueSuccess, enqueueError } = useMessages();
  const searchText = useRecoilValue(channelGroupSearchTextAtom);
  const listFetchKey = ['/v1/channelGroups', searchText];

  const createChannelGroup = async (channelGroupReceived: ChannelGroup) => {
    try {
      await Axios.post('/v1/channelGroups', channelGroupReceived);
      enqueueSuccess(t('channel_groups:channel_group_created'));
      mutate(listFetchKey, async (items: ChannelGroup[]) => [
        { ...channelGroupReceived, id: -1 },
        ...items,
      ]);
    } catch (error) {
      if (Axios.isAxiosError(error))
        enqueueError(
          `${t('channel_groups:error_creating')}\n${t(
            `api_errors_messages:${error?.response?.data?.message}`
          )}`
        );
      throw error;
    }
  };

  const editChannelGroup = async (
    channelGroupReceived: ChannelGroup,
    _licenseId?: number | null
  ) => {
    try {
      await Axios.put(`/v1/channelGroups/${channelGroupReceived.id}`, channelGroupReceived);

      mutate(listFetchKey, async (items: ChannelGroup[]) =>
        items.map((_item: ChannelGroup) =>
          _item.id === channelGroupReceived.id ? channelGroupReceived : _item
        )
      );

      if (
        (typeof channelGroupReceived.license?.id === 'number' &&
          channelGroupReceived.license.id !== _licenseId) ||
        (!_licenseId && channelGroupReceived.license?.id)
      ) {
        enqueueSuccess(t('channel_groups:channel_group_changed_with_license'));
        return;
      }

      if (typeof _licenseId === 'number' && !channelGroupReceived.license?.id) {
        enqueueSuccess(t('channel_groups:channel_group_has_been_disassociated'));
        return;
      }

      enqueueSuccess(t('channel_groups:channel_group_changed'));
    } catch (error) {
      if (Axios.isAxiosError(error))
        enqueueError(
          `${t('channel_groups:error_editing')}\n${t(
            `api_errors_messages:${error?.response?.data?.message}`
          )}`
        );
      throw error;
    }
  };

  const deleteChannelGroup = async (id: number) => {
    try {
      await Axios.delete(`/v1/channelGroups/${id}`);
      enqueueSuccess(t('channel_groups:channel_group_deleted'));
      mutate(listFetchKey, (items: ChannelGroup[]) =>
        items.filter((_item: ChannelGroup) => _item.id !== id)
      );
    } catch (error) {
      if (Axios.isAxiosError(error))
        enqueueError(
          `${t('channel_groups:error_deleting')}\n${t(
            `api_errors_messages:${error?.response?.data?.message}`
          )}`
        );
    }
  };

  return {
    deleteChannelGroup,
    createChannelGroup,
    editChannelGroup,
  };
}
