import useMessages from 'hooks/useMessages';
import { useTranslation } from 'react-i18next';
import Axios from 'axios';
import { PermissionRole } from 'types/user.types';
import { useHavePermission } from 'hooks/useHavePermission';
import { mutate } from 'swr';
import { License } from 'types/license.types';

export function useLicenseModels() {
  const { enqueueSuccess, enqueueError } = useMessages();
  const { t } = useTranslation();
  const editPermission = useHavePermission(PermissionRole.EDIT_LICENSE);
  const listFetchKey = '/v1/licenses';

  const updateLicenseName = async ({ id, name }: { id: number; name: string }) => {
    if (!editPermission) {
      enqueueError(
        `${t('license_model:error_editing')}\n${t('permission:you_dont_have_permission')}`
      );
      throw new Error(t('permission:you_dont_have_permission'));
    }

    try {
      await Axios.put<License>(`/v1/licenses/${id}`, { name });
      enqueueSuccess(t('license_models:license_updated_successfully'));
      mutate(listFetchKey, async (items: License[]) =>
        items.map((item) => (item.id === id ? { ...item, name } : item))
      );
    } catch (error) {
      if (Axios.isAxiosError(error))
        enqueueError(
          `${t('license_model:error_editing')}\n${t(
            `api_errors_messages:${error?.response?.data?.message ?? error?.response?.status}`
          )}`
        );
      console.error(error);
    }
  };

  return {
    updateLicenseName,
  };
}
