import { Edit, ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import {
  Box,
  IconButton,
  LinearProgress,
  Paper,
  Tooltip,
  Typography,
  Skeleton,
} from '@mui/material';
import { themeSelectedAtom } from 'atoms/config';
import VideoCamCloud from 'components/core/Icons/VideoCamCloud';
import { formatLicenseDescription } from 'helpers/formatLicenseDescription';
import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { License } from 'types/license.types';
import { PermissionRole } from 'types/user.types';
import Permissioned from 'components/Permissioned';
import LicenseNameFormDialog from './LicenseNameFormDialog';

interface Props {
  licenses: License[];
}

export default function LicenseModelList({ licenses }: Props) {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [isAtStart, setIsAtStart] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);
  const [licenseNameModalOpened, setLicenseNameModalOpened] = useState(false);
  const [selectedLicenseToEdit, setSelectedLicenseToEdit] = useState<{
    id: number;
    name: string | null;
  } | null>(null);

  const handleCloseLicenseNameModal = () => {
    setLicenseNameModalOpened(false);
    setSelectedLicenseToEdit(null);
  };

  const handleSetSelectedLicenseToEdit = ({ id, name }: { id: number; name: string }) => {
    setSelectedLicenseToEdit({
      id,
      name,
    });
    setLicenseNameModalOpened(true);
  };

  const scroll = (direction: 'left' | 'right') => {
    if (scrollRef.current) {
      const { scrollLeft, clientWidth, scrollWidth } = scrollRef.current;
      const scrollTo = direction === 'left' ? scrollLeft - clientWidth : scrollLeft + clientWidth;
      scrollRef.current.scrollTo({ left: scrollTo, behavior: 'smooth' });

      // Update the state after scrolling
      setTimeout(() => {
        setIsAtStart(Number(scrollRef?.current?.scrollLeft) === 0);
        setIsAtEnd(Number(scrollRef?.current?.scrollLeft) + clientWidth >= scrollWidth);
      }, 300);
    }
  };

  useEffect(() => {
    if (scrollRef.current) {
      const { clientWidth, scrollWidth } = scrollRef.current;
      setIsAtEnd(clientWidth >= scrollWidth);
    }
  }, [licenses]);

  const chunkArray = (arr: License[], size: number) => {
    const result = [];
    for (let i = 0; i < arr.length; i += size) {
      result.push(arr.slice(i, i + size));
    }
    return result;
  };

  const licenseGroups = chunkArray(licenses, Math.floor(Math.random() * 4) + 4);
  const { t } = useTranslation();
  return (
    <Box sx={{ position: 'relative', width: '100%' }}>
      {!isAtStart && (
        <Tooltip title={t('video:back')}>
          <IconButton
            size="small"
            onClick={() => scroll('left')}
            sx={{
              position: 'absolute',
              left: 0,
              top: '50%',
              transform: 'translateY(-50%)',
              height: '50px',
              width: '50px',
              zIndex: 1,
              color: 'white',
            }}
            disabled={isAtStart}
          >
            <ArrowBackIos />
          </IconButton>
        </Tooltip>
      )}
      <Box
        ref={scrollRef}
        sx={{
          display: 'flex',
          overflowX: 'auto',
          scrollbarWidth: 'none',
          scrollBehavior: 'smooth',
          gap: 3,
        }}
      >
        {licenseGroups.map((group, groupIndex) => (
          <Box key={Number(groupIndex)} sx={{ display: 'flex', gap: 3 }}>
            {group.map((license, licenseIndex) => (
              <LicenseModelPaper
                key={license.id}
                license={license}
                consumedChannels={license.consumedChannels}
                isLastItem={
                  groupIndex === licenseGroups.length - 1 && licenseIndex === group.length - 1
                }
                onEdit={handleSetSelectedLicenseToEdit}
              />
            ))}
          </Box>
        ))}
      </Box>
      {!isAtEnd && (
        <Tooltip title={t('video:foward')}>
          <IconButton
            onClick={() => scroll('right')}
            size="small"
            sx={{
              position: 'absolute',
              right: 0,
              top: '50%',
              transform: 'translateY(-50%)',
              height: '50px',
              width: '50px',
              zIndex: 1,
              color: 'white',
            }}
          >
            <ArrowForwardIos />
          </IconButton>
        </Tooltip>
      )}

      {selectedLicenseToEdit && (
        <LicenseNameFormDialog
          open={licenseNameModalOpened}
          onClose={handleCloseLicenseNameModal}
          license={selectedLicenseToEdit}
        />
      )}
    </Box>
  );
}

function LicenseModelPaper({
  license,
  isLastItem,
  consumedChannels,
  onEdit,
}: {
  license: License;
  isLastItem: boolean;
  consumedChannels: number;
  onEdit: ({ id, name }: { id: number; name: string }) => void;
}) {
  const { t } = useTranslation();
  const themeSelected = useRecoilValue(themeSelectedAtom);

  const consumedPercentage =
    consumedChannels > license.maxAllowedChannels
      ? 100
      : Math.floor((consumedChannels / license.maxAllowedChannels) * 100);

  const color =
    // eslint-disable-next-line no-nested-ternary
    consumedPercentage >= 90 ? 'error' : consumedPercentage >= 80 ? 'warning' : 'success';

  return (
    <Paper
      sx={{
        bgcolor: 'action.selected',
        width: '273px',
        height: '328px',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',

        ...(isLastItem && {
          borderTopRightRadius: '8px',
          borderBottomRightRadius: '8px',
        }),
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          pt: 2,
          px: 2,
        }}
      >
        <Box
          sx={{
            bgcolor: 'GrayText',
            borderRadius: '50%',
            width: '40px',
            height: '40px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <VideoCamCloud />
        </Box>
        <Permissioned role={PermissionRole.EDIT_LICENSE}>
          <Tooltip title={t('license_model:edit_license_name')}>
            <IconButton
              aria-label="edit"
              onClick={() =>
                onEdit({
                  id: license.id,
                  name: license.name ?? '',
                })
              }
              size="medium"
            >
              <Edit />
            </IconButton>
          </Tooltip>
        </Permissioned>
      </Box>

      <Box
        sx={{
          px: 2,
          pt: 3,
        }}
      >
        <Typography variant="h5" fontWeight="bold">
          {license.name ?? t('license_models:unnamed_license')}
        </Typography>
        <Typography
          variant="body1"
          sx={(theme) => ({
            color: theme.palette.text.secondary,
            mt: 1,
            mb: 3,
          })}
        >
          {formatLicenseDescription(license.licenseType, t)}
        </Typography>
        <Typography variant="h6" fontWeight="bold">
          {consumedChannels > 0 ? consumedChannels : 0} / {license.maxAllowedChannels}
        </Typography>
        <Typography variant="caption">
          {t('license_models:used_channel', { count: consumedChannels })}
        </Typography>
        <LinearProgress
          value={consumedPercentage}
          variant="determinate"
          color={color === 'success' ? 'primary' : color}
          sx={(theme) => ({
            height: '12px',
            borderRadius: '40px',
            bgcolor: themeSelected === 'DARK' ? '#ddd' : 'rgba(0, 0, 0, 0.2)',
            mb: 1,
            mt: 0.5,
          })}
        />
        <Typography
          variant="caption"
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <StatusCircle color={color} />
          {(() => {
            const remainingLicenses = license.maxAllowedChannels - consumedChannels;

            if (license.maxAllowedChannels < consumedChannels) {
              return t('license_models:consumed_channels_greater_than_allowed');
            }

            if (remainingLicenses === 0) {
              return t('license_models:no_license_available');
            }

            return t('license_models:available_licenses', {
              count: remainingLicenses > 0 ? remainingLicenses : license.maxAllowedChannels,
            });
          })()}
        </Typography>
      </Box>
    </Paper>
  );
}

function StatusCircle({ color }: { color: string }) {
  return (
    <Box
      sx={(theme) => ({
        // @ts-ignore
        bgcolor: theme.palette[color].main,
        width: '10px',
        height: '10px',
        borderRadius: '50%',
        display: 'inline-block',
      })}
    />
  );
}

export function LicenseModelListLoading() {
  return (
    <>
      {Array.from(new Array(5)).map((_, index) => (
        <Paper
          key={Number(index)}
          sx={{
            bgcolor: 'action.selected',
            width: '273px',
            height: '328px',
            borderRadius: '8px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              pt: 2,
              px: 2,
            }}
          >
            <Box
              sx={{
                bgcolor: 'GrayText',
                borderRadius: '50%',
                width: '40px',
                height: '40px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <VideoCamCloud />
            </Box>
            <IconButton aria-label="edit" size="medium" disabled>
              <Edit />
            </IconButton>
          </Box>

          <Box
            sx={{
              px: 2,
              pt: 3,
            }}
          >
            <Typography variant="h5" fontWeight="bold">
              <Skeleton width="100%" height="46px" />
            </Typography>
            <Typography
              variant="body2"
              sx={(theme) => ({
                color: theme.palette.text.secondary,
                mt: 1,
                mb: 2,
              })}
            >
              <Skeleton width="100%" height="20px" />
              <Skeleton width="100%" height="20px" />
            </Typography>
            <Typography variant="h6" fontWeight="bold">
              <Skeleton width="45%" height="64px" />
            </Typography>
            <LinearProgress
              value={0}
              variant="determinate"
              color="primary"
              sx={(theme) => ({
                height: '12px',
                borderRadius: '40px',
                bgcolor: 'rgba(0, 0, 0, 0.2)',
                mb: 2,
                mt: 0.5,
              })}
            />
            <Typography
              variant="caption"
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
              }}
            >
              <Skeleton width="100%" />
            </Typography>
          </Box>
        </Paper>
      ))}
    </>
  );
}
