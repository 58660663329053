import React, { useRef, useCallback, useEffect } from 'react';
import { OpenInNew, Pause, PlayArrow, ZoomOut } from '@mui/icons-material';
import { IconButton, Box, alpha, Typography, Link, SxProps, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { liveSelector, pausedAtom, selectedDateAtom } from 'atoms/playback';
import { atomFamily, useRecoilState, useRecoilValue } from 'recoil';
import { timeFormat, timeSecond } from 'd3';
import { ProtocolType, VideoLiveSource } from 'types/channels.types';
import { Live as LiveIcon, UnstableConnection as UnstableChannelIcon } from 'components/core/Icons';
import NetworkCheckIcon from '@mui/icons-material/NetworkCheck';
import { Link as RouterLink } from 'react-router-dom';
import routes from 'helpers/routes';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import { useMeasure } from 'react-use';
import { UseMeasureRect } from 'react-use/lib/useMeasure';
import { MEDIUM, SMALL } from 'components/MosaicVideo/responsive.service';
import { unstableChannelsAtom, unstableConnectionsAtom } from 'atoms/mosaics';
import { getLatencyByChannelId } from 'components/core/VideoPlayer/hlsPlugin';
import { Mode, themeSelectedAtom } from 'atoms/config';
import axios from 'axios';
import { VideoWithPlayback as V2VideoWithPlayback } from 'pages/MosaicViewPage/V2BottomPanel';
import { ChannelSimple } from 'types/mosaics.types';
import VideoWithPlaybackFullscreen from 'components/CronologicBarFullscreen/VideoWithPlaybackFullscreen';
import { useQuery } from 'hooks';
import { onlineChannelAtom } from 'atoms/channels';

const zoomAtom = atomFamily<number | undefined, string>({
  key: 'src/components/Channel/ChannelVideo/zoomAtom',
  default: undefined,
});

const zoomFullscreenAtom = atomFamily<number | undefined, string>({
  key: 'src/components/Channel/ChannelVideo/zoomFullscreenAtom',
  default: undefined,
});

interface ChannelLatency {
  id: string;
  latency: number;
}

function ChannelVideo({
  channel,
  enableZoom = true,
  widescreen,
  mosaicId = null,
  videoLiveSource,
  setVideoLiveSource,
  children,
  fullscreen = false,
}: {
  channel: ChannelSimple;
  enableZoom?: boolean;
  widescreen?: boolean;
  mosaicId?: number | null;
  videoLiveSource?: VideoLiveSource;
  setVideoLiveSource?: (source: VideoLiveSource) => void;
  children?: React.ReactNode;
  fullscreen?: boolean;
}) {
  const container = useRef(null);
  const [zoom, setZoom] = useRecoilState(
    fullscreen
      ? zoomFullscreenAtom(`${mosaicId}-${channel.id}-fullscreen`)
      : zoomAtom(`${mosaicId}-${channel.id}`)
  );
  const isRTSP = channel.connectionTypeEnum === ProtocolType.RTSP;
  const channelURL = channel.url;
  const live = useRecoilValue(liveSelector({ mosaicId, channelId: channel.id }));

  const onZoomChange = useCallback(
    (rZoom: number) => {
      setZoom(rZoom);
    },
    [setZoom]
  );
  const isOnlineChannel = useRecoilValue(onlineChannelAtom(channel.id));
  const [enabled, setEnabled] = React.useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setEnabled(true);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <Box
      ref={container}
      sx={{
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'flex',
      }}
      aria-label={`video of channel ${channel.name}`}
      role="region"
    >
      {fullscreen ? (
        <VideoWithPlaybackFullscreen
          key={`channel-${channel.id}-fullcreen`}
          {...{
            channel,
            widescreen,
            onZoomChange,
            zoom,
            enableZoom,
            mosaicId,
            videoLiveSource,
          }}
        />
      ) : (
        <V2VideoWithPlayback
          key={`channel-${channel.id}`}
          {...{
            channel,
            widescreen,
            onZoomChange,
            zoom,
            enableZoom,
            mosaicId,
            videoLiveSource,
          }}
        />
      )}
      {live && enabled && !isOnlineChannel && <ChannelVideo.Offline />}
      {!fullscreen && (
        <ChannelVideo.Tags
          {...{
            mosaicId,
            isRTSP,
            channelURL,
            existSrc: videoLiveSource?.url,
            channelId: channel.id,
          }}
        />
      )}
      {children}
    </Box>
  );
}

ChannelVideo.Tags = function TopRightTags({
  mosaicId,
  channelId,
  isRTSP,
  channelURL,
}: {
  mosaicId: number | null;
  channelId: number;
  isRTSP: boolean;
  channelURL: string | undefined;
}) {
  const [unstableConnections, setUnstableConnections] = useRecoilState(unstableConnectionsAtom);
  const [unstableChannels, setUnstableChannels] = useRecoilState(unstableChannelsAtom);
  const live = useRecoilValue(liveSelector({ mosaicId, channelId }));
  const [measureRef, measures] = useMeasure<HTMLButtonElement>();
  const selectedDate = useRecoilValue(selectedDateAtom({ mosaicId, channelId }));
  const intervalConnections = useRef<NodeJS.Timeout | null>(null);
  const intervalChannels = useRef<NodeJS.Timeout | null>(null);
  const query = useQuery();

  const startDateS = query.get('startDate');

  const timeVideo = React.useMemo(
    function CalculePlaybackDateTime() {
      const startDate = startDateS ? timeSecond.offset(new Date(startDateS)) : null;

      return selectedDate ? timeSecond.offset(new Date(selectedDate)) : startDate;
    },
    [selectedDate, startDateS]
  );

  const baseURL = process.env.REACT_APP_VIDEO_LIVE_SERVER || 'http://localhost:9011/';
  const username = process.env.REACT_APP_VIDEO_LIVE_USERNAME || 'user';
  const password = process.env.REACT_APP_VIDEO_LIVE_PASSWORD || 'password';

  const isThereConnectionInstability = React.useMemo(
    () => unstableConnections.some((id) => id === channelId),
    [channelId, unstableConnections]
  );

  const isThereChannelInstability = React.useMemo(
    () => unstableChannels.some((id) => id === channelId),
    [channelId, unstableChannels]
  );

  React.useEffect(
    function listeningConnectionInstabilities() {
      intervalConnections.current = setInterval(() => {
        const latencia = getLatencyByChannelId(channelId);

        if (latencia > 1500) {
          // 1500ms is the threshold for unstable connections
          setUnstableConnections((connections) => {
            if (connections.includes(channelId)) {
              return connections;
            }
            return [...connections, channelId];
          });
        } else {
          setUnstableConnections((connections) => connections.filter((chId) => chId !== channelId));
        }
      }, 10000);

      return () => {
        intervalConnections.current && clearInterval(intervalConnections.current);
      };
    },

    [channelId, intervalConnections, setUnstableConnections]
  );

  React.useEffect(
    function checkRTSPLatencyEvery30Seconds() {
      if (isRTSP) {
        intervalChannels.current = setInterval(async () => {
          try {
            const { data: channelLantecy } = await axios.post<ChannelLatency>(
              'live/latency',
              {
                url: channelURL,
                id: channelId,
              },
              {
                baseURL,
                auth: {
                  username,
                  password,
                },
              }
            );

            const numberID = Number(channelLantecy.id);

            if (numberID > 0 && channelLantecy.latency > 475) {
              // 475ms is the threshold for unstable channels
              setUnstableChannels((channels) => {
                if (channels.includes(numberID)) {
                  return channels;
                }
                return [...channels, numberID];
              });
            } else {
              setUnstableChannels((channels) => channels.filter((chId) => chId !== numberID));
            }
          } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err);
          }
        }, 30000);

        return () => {
          intervalChannels.current && clearInterval(intervalChannels.current);
        };
      }

      return () => {};
    },
    [
      baseURL,
      channelId,
      channelURL,
      isRTSP,
      password,
      setUnstableChannels,
      username,
      intervalChannels,
    ]
  );

  React.useEffect(
    function clearStates() {
      // Clear unstable states when the mosaic changes
      setUnstableConnections([]);
      setUnstableChannels([]);
    },
    [mosaicId, setUnstableChannels, setUnstableConnections]
  );

  return (
    <Box
      ref={measureRef}
      sx={{
        borderRadius: live ? '100px' : '4px',
        p: 0.5,
        position: 'absolute',
        top: '18px',
        right: '18px',
        textAlign: 'right',
        ...(live ? { display: 'flex' } : {}),
        alignItems: 'center',
        gap: 0.5,
      }}
    >
      {live ? (
        <>
          <UnstableChannelTag {...{ isThereChannelInstability }} />
          <UnstableConnectionTag {...{ isThereConnectionInstability }} />
          <LiveTag {...{ measures }} />
        </>
      ) : (
        <RecordTag {...{ measures, timeVideo }} />
      )}
    </Box>
  );
};

ChannelVideo.Offline = function OfflineChannel() {
  return (
    <Box
      sx={{
        borderRadius: '100px',
        p: 0.5,
        position: 'absolute',
        top: '18px',
        left: '18px',
        display: 'flex',
        bgcolor: alpha('#555', 0.4),
        alignItems: 'center',
        gap: 0.5,
        width: '46px',
        height: '20px',
        justifyContent: 'center',
        cursor: 'default',
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          px: 1,
          gap: 0.5,
        }}
      >
        <StatusTag />
        <Typography mt={0.2} variant="overline" fontWeight="bold" fontSize="0.7rem" color="white">
          OFF
        </Typography>
      </Box>
    </Box>
  );
};

ChannelVideo.ZoomOutAction = function ZoomOutAction({
  channel,
  mosaicId = null,
}: {
  channel: ChannelSimple;
  mosaicId?: number | null;
}) {
  const { t } = useTranslation(['video']);
  const [zoom, setZoom] = useRecoilState(zoomAtom(`${mosaicId}-${channel.id}`));

  const handleZoomOutClick = () => {
    setZoom(1);
  };

  if (Math.floor((zoom ?? 1) * 100) === 100) {
    return null;
  }

  return (
    <IconButton
      aria-label="zoom out"
      onClick={handleZoomOutClick}
      size="small"
      color="primary"
      title={t('video:remove_zoom')}
    >
      <ZoomOut fontSize="small" />
    </IconButton>
  );
};

ChannelVideo.SeeMoreAction = function SeeMoreAction({ channel }: { channel: ChannelSimple }) {
  const { t } = useTranslation(['video']);
  return (
    <Link
      component={RouterLink}
      underline="none"
      aria-label="OpenInNew"
      to={routes.customer.channel.view(channel.clientId, channel.id)}
      title={t('video:go_to_channel_view')}
    >
      <OpenInNew fontSize="small" sx={{ color: 'text.primary' }} />
    </Link>
  );
};

ChannelVideo.PlayPauseAction = function PlayPauseAction({ channel }: { channel: ChannelSimple }) {
  const [paused, setPaused] = useRecoilState(pausedAtom(channel.id));
  const { t } = useTranslation(['video']);

  return (
    <IconButton
      aria-label={paused ? 'play' : 'pause'}
      onClick={() => setPaused(!paused)}
      size="small"
      title={paused ? t('video:play_video') : t('video:pause_video')}
    >
      {paused ? <PlayArrow fontSize="small" /> : <Pause fontSize="small" />}
    </IconButton>
  );
};

ChannelVideo.BottomPanel = function BottomPanel({
  children,
  margin,
  sx,
}: {
  children: React.ReactNode;
  margin?: number;
  sx?: SxProps;
}) {
  const themeSelected = useRecoilValue(themeSelectedAtom);

  return (
    <Box
      role="region"
      aria-label="channel's information"
      sx={{
        bgcolor: (theme) =>
          themeSelected === Mode.DARK ? alpha('#000', 0.74) : alpha('#fff', 0.74),
        // bgcolor: (theme) => alpha('#fff', 0.74),
        borderRadius: '4px',
        position: 'absolute',
        bottom: margin ?? '8px',
        right: margin ?? '8px',
        left: margin ?? '8px',
        '& .MuiIconButton-root': {
          p: 0,
        },
        ...sx,
      }}
      onClick={(e) => e.stopPropagation()}
    >
      {children}
    </Box>
  );
};

export default ChannelVideo;

function UnstableConnectionTag({
  isThereConnectionInstability,
}: {
  isThereConnectionInstability: boolean;
}) {
  const unstableConnections = useRecoilValue(unstableConnectionsAtom);
  const isDanger = React.useMemo(() => unstableConnections.length > 10, [unstableConnections]);

  return isThereConnectionInstability ? (
    <IconButton
      aria-label="unstable connection"
      size="small"
      sx={(theme) => ({
        bgcolor: !isDanger ? theme.palette.warning.main : theme.palette.error.main,
        color: 'black',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 5,
        gap: theme.spacing(0.8),
        px: theme.spacing(0.8),
        ':hover': {
          bgcolor: !isDanger ? theme.palette.warning.light : theme.palette.error.light,
          cursor: 'default',
        },
      })}
    >
      <NetworkCheckIcon fontSize="small" color="inherit" />
    </IconButton>
  ) : null;
}

function UnstableChannelTag({ isThereChannelInstability }: { isThereChannelInstability: boolean }) {
  if (!isThereChannelInstability) return null;

  return (
    <IconButton
      aria-label="unstable channel"
      size="small"
      sx={(theme) => ({
        bgcolor: theme.palette.warning.dark,
        color: 'black',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 5,
        gap: theme.spacing(0.8),
        px: theme.spacing(0.8),
        ':hover': {
          bgcolor: theme.palette.warning.main,
          cursor: 'default',
        },
      })}
    >
      <UnstableChannelIcon fontSize="small" color="inherit" />
    </IconButton>
  );
}

function LiveTag({ measures }: { measures: UseMeasureRect }) {
  const { t } = useTranslation();
  return (
    <IconButton
      aria-label="live"
      size="small"
      sx={(theme) => ({
        bgcolor: alpha('#000', 0.74),
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 5,
        gap: theme.spacing(0.5),
        px: theme.spacing(0.5),
        ':hover': {
          bgcolor: theme.palette.secondary.dark,
          cursor: 'default',
        },
      })}
    >
      <LiveIcon />
      <Typography
        sx={{
          color: 'white',
          textTransform: 'uppercase',
          lineHeight: '1',
          fontSize: '0.7rem',
          display: SMALL.upper(measures) ? 'block' : 'none',
          mr: 1,
        }}
        variant="caption"
        className="live-caption"
      >
        {t('video:live')}
      </Typography>
    </IconButton>
  );
}

function RecordTag({ measures, timeVideo }: { measures: UseMeasureRect; timeVideo: Date | null }) {
  const { t } = useTranslation();
  return (
    <IconButton
      aria-label="playback's time"
      size="small"
      role="region"
      sx={(theme) => ({
        bgcolor: alpha('#000', 0.74),
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 5,
        gap: theme.spacing(0.5),
        px: theme.spacing(0.5),
        ':hover': {
          bgcolor: alpha('#000', 0.85),
          cursor: 'default',
        },
      })}
    >
      {!timeVideo ? (
        <Tooltip title={t('_common:no_recording')}>
          <VideocamOffIcon fontSize="small" />
        </Tooltip>
      ) : (
        <Typography
          sx={{ paddingX: 0.5, paddingY: MEDIUM.upper(measures) ? 1 : 0, color: 'white' }}
        >
          {t('video:recorded_on', {
            date_and_time: timeFormat('%d %b %Y %H:%M:%S')(timeVideo),
          })}
        </Typography>
      )}
    </IconButton>
  );
}

function StatusTag() {
  return (
    <Box
      sx={(theme) => ({
        height: '14px',
        width: '14px',
        bgcolor: theme.palette.error.main,
        borderRadius: '50%',
      })}
    />
  );
}
