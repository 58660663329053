import Axios from 'axios';
import { channelSelectedAtom } from 'atoms/channels';
import useMessages from 'hooks/useMessages';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { mutate } from 'swr';
import { PermissionRole } from 'types/user.types';
import { Channel, RecordingType } from 'types/channels.types';
import { useHavePermission } from 'hooks/useHavePermission';

export function useChannelsApi(customerId: number) {
  const { t } = useTranslation(['channels', 'permission', 'api_errors_messages']);
  const { enqueueSuccess, enqueueError } = useMessages();
  const setChannelSelected = useSetRecoilState(channelSelectedAtom);
  const editPermission = useHavePermission(PermissionRole.EDIT_CHANNEL);
  const insertPermission = useHavePermission(PermissionRole.INSERT_CHANNEL);
  const removePermission = useHavePermission(PermissionRole.REMOVE_CHANNEL);

  const listFetchKey = `/v1/clients/${customerId}/channels`;

  const createChannel = async (channel: Channel): Promise<Channel> => {
    if (!insertPermission) {
      enqueueError(`${t('channels:error_creating')}\n${t('permission:you_dont_have_permission')}`);
      throw new Error(t('permission:you_dont_have_permission'));
    }
    try {
      const formattedChannel = formatChannel(channel);
      const { data } = await Axios.post<Channel>(
        `/v1/clients/${customerId}/channels`,
        formattedChannel
      );
      enqueueSuccess(t(channel.url ? 'channels:channel_created' : 'channels:channel_rtmp_created'));
      setChannelSelected(data.id);
      mutate(listFetchKey, async (items?: Channel[]) => [
        { channelReceived: channel, id: data.id },
        ...(items || []),
      ]);

      return data;
    } catch (error) {
      if (Axios.isAxiosError(error))
        enqueueError(
          `${t('channels:error_creating')}\n${t(
            `api_errors_messages:${error?.response?.data?.message}`
          )}`
        );
      throw error;
    }
  };

  const updateChannel = async (channel: Channel) => {
    if (!editPermission) {
      enqueueError(`${t('channels:error_editing')}\n${t('permission:you_dont_have_permission')}`);
      throw new Error(t('permission:you_dont_have_permission'));
    }
    try {
      const formattedChannel = formatChannel(channel);
      await Axios.put(`/v1/clients/${customerId}/channels/${channel.id}`, formattedChannel);
      enqueueSuccess(t('channels:channel_changed'));
      setChannelSelected(channel.id);
      mutate(listFetchKey, async (items?: Channel[]) =>
        items?.map((_item: Channel) => (_item.id === channel.id ? channel : _item))
      );
    } catch (error) {
      if (Axios.isAxiosError(error))
        enqueueError(
          `${t('channels:error_editing')}\n${t(
            `api_errors_messages:${error?.response?.data?.message}`
          )}`
        );
      throw error;
    }
  };

  const deleteChannel = async (channelId: number) => {
    if (!removePermission) {
      enqueueError(`${t('channels:error_deleting')}\n${t('permission:you_dont_have_permission')}`);
      throw new Error(t('permission:you_dont_have_permission'));
    }
    try {
      await Axios.delete(`/v1/clients/${customerId}/channels/${channelId}`);
      enqueueSuccess(t('channels:channel_deleted'));
      setChannelSelected(null);
      mutate(listFetchKey, async (items?: Channel[]) =>
        items?.filter((_item: Channel) => _item.id !== channelId)
      );
    } catch (error) {
      if (Axios.isAxiosError(error))
        enqueueError(
          `${t('channels:error_deleting')}\n${t(
            `api_errors_messages:${error?.response?.data?.message}`
          )}`
        );
      throw error;
    }
  };

  const createBatchChannels = async (channels: Channel[]): Promise<Channel[]> => {
    if (!insertPermission) {
      enqueueError(`${t('channels:error_creating')}\n${t('permission:you_dont_have_permission')}`);
      throw new Error(t('permission:you_dont_have_permission'));
    }

    try {
      const formattedChannels = formatChannels(channels);
      const { data } = await Axios.post<Channel[]>(
        `/v1/clients/${customerId}/channels/many`,
        formattedChannels
      );
      // Se houver apenas 1 elemento que a URL não seja nula, significa que é um lote RTSP
      // e se não encontrar nenhum elemento com URL, significa que é um lote RTMP
      const hasUrl = channels.some((channel) => channel.url && channel.url.trim() !== '');

      enqueueSuccess(
        t(hasUrl ? 'channels:channels_created' : 'channels:batch_channel_rtmp_created')
      );
      mutate(listFetchKey, undefined, true);

      return data;
    } catch (error) {
      if (Axios.isAxiosError(error))
        enqueueError(
          `${t('channels:error_creating')}\n${t(
            `api_errors_messages:${error?.response?.data?.message || error?.response?.data?.error}`
          )}`
        );

      console.error(error);
      throw error;
    }
  };

  const enableInBatch = async (channelIds: Number[]) => {
    if (!editPermission) {
      enqueueError(`${t('channels:error_enable')}\n${t('permission:you_dont_have_permission')}`);
      throw new Error(t('permission:you_dont_have_permission'));
    }
    try {
      await Axios.post(`/v1/clients/${customerId}/channels/enable`, channelIds);
      mutate(listFetchKey, async (_items: Channel[]) =>
        _items.map((_item) => (channelIds.includes(_item.id) ? { ..._item, status: true } : _item))
      );

      enqueueSuccess(t('channels:enabled_successful'));
    } catch (error) {
      if (Axios.isAxiosError(error))
        enqueueError(
          `${t('channels:error_enable')}\n${t(
            `api_errors_messages:${error?.response?.data?.message}`
          )}`
        );
      throw error;
    }
  };

  const disableInBatch = async (channelIds: Number[]) => {
    if (!editPermission) {
      enqueueError(
        `${t('channels:error_disabled_channels')}\n${t('permission:you_dont_have_permission')}`
      );
      throw new Error(t('permission:you_dont_have_permission'));
    }
    try {
      await Axios.post(`/v1/clients/${customerId}/channels/disable`, channelIds);
      mutate(listFetchKey, async (_items: Channel[]) =>
        _items.map((_item) => (channelIds.includes(_item.id) ? { ..._item, status: false } : _item))
      );
      enqueueSuccess(t('channels:disabled_successful'));
    } catch (error) {
      if (Axios.isAxiosError(error))
        enqueueError(
          `${t('channels:error_disabled_channels')}\n${t(
            `api_errors_messages:${error?.response?.data?.message}`
          )}`
        );
      throw error;
    }
  };

  const deleteInBatch = async (channelIds: Number[]) => {
    if (!editPermission) {
      enqueueError(
        `${t('channels:error_delete_all_channels')}\n${t('permission:you_dont_have_permission')}`
      );
      throw new Error(t('permission:you_dont_have_permission'));
    }
    try {
      await Axios.delete(`/v1/clients/${customerId}/channels?channelIds=${channelIds}`);
      mutate(listFetchKey);
      enqueueSuccess(t('channels:delete_all_successful'));
    } catch (error) {
      if (Axios.isAxiosError(error))
        enqueueError(
          `${t('channels:error_delete_all_channels')}\n${t(
            `api_errors_messages:${error?.response?.data?.message}`
          )}`
        );
      throw error;
    }
  };

  return {
    createChannel,
    updateChannel,
    deleteChannel,
    createBatchChannels,
    enableInBatch,
    disableInBatch,
    deleteInBatch,
  };
}

export const normalizeChannel = (channel: Channel): Channel => ({
  ...channel,
  idMask: String(channel.id).padStart(6, '0'),
  urlOnlyRecord: channel.urlOnlyRecord || '',
});

// I decided use any to avoid cicle dependency in other services like useIntelbrasAPI
// In future it can be refactored to use one service to channels
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function formatChannel(channel: Channel | any) {
  let formattedChannel: Channel = channel;

  if (channel.channelGroup?.license) {
    formattedChannel = {
      ...channel,
      retentionTime: channel.channelGroup.license.licenseType.retentionTime,
      recordingType: (channel.channelGroup.license.licenseType
        .recordingType as unknown) as RecordingType,
    };
  }

  return formattedChannel;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function formatChannels(channels: Channel[] | any) {
  let formattedChannels: Channel[] = channels;

  // @ts-ignore
  const channelsHasLicense = channels.some((value) => value.channelGroup?.license);
  if (channelsHasLicense) {
    // @ts-ignore
    formattedChannels = channels.map((channel) => ({
      ...channel,
      retentionTime:
        channel.channelGroup?.license?.licenseType?.retentionTime || channel.retentionTime,
      recordingType:
        channel.channelGroup?.license?.licenseType?.recordingType || channel.recordingType,
    }));
  }

  return formattedChannels;
}
