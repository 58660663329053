import React from 'react';
import { useRecoilState } from 'recoil';
import { Redirect, useHistory } from 'react-router-dom';
import { Form } from 'components/core';
import { useQuery } from 'hooks';
import { useTranslation } from 'react-i18next';
import { Box, Button, CircularProgress, Divider } from '@mui/material';
import { batchIntelbrasFormAtom, intelbrasConnectionTestsResponse } from 'atoms/intelbras';
import routes from 'helpers/routes';
import { CustomizedResponseCodeEnum } from 'types/channels.types';
import { useIntelbrasApi } from 'hooks/channels/manufactures/useIntelbrasApi';
import PreviewIntelbrasChannel from './PreviewIntelbrasChannel';

interface PreviewInterface {
  customerId: string;
}
export function PreviewBatchIntelbras({ customerId }: PreviewInterface) {
  const [data, setData] = useRecoilState(batchIntelbrasFormAtom);
  const [testedChannels, setTestedChannels] = useRecoilState(intelbrasConnectionTestsResponse);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const { createBatchChannels } = useIntelbrasApi(Number(customerId));

  const { t } = useTranslation();
  const history = useHistory();
  const query = useQuery();

  const handleSave = React.useCallback(async () => {
    try {
      setIsSubmitting(true);
      await createBatchChannels(data);

      setIsSubmitting(false);
      history.push(routes.customer.root(Number(customerId)));
    } catch (error) {
      setIsSubmitting(false);
      throw error;
    }
  }, [createBatchChannels, customerId, data, history]);

  const computedChannels = React.useMemo(
    () =>
      Array.from({ length: data.length }).map((_, i) => ({
        uuid: data[i].uuid,
        success: 0 as CustomizedResponseCodeEnum,
      })),
    [data]
  );
  React.useEffect(
    function addUUIDinTestedChannels() {
      if (testedChannels.length > 1) {
        setTestedChannels(
          testedChannels.map((e, index) => ({
            ...e,
            uuid: data[index].uuid,
          }))
        );
      } else {
        // @ts-ignore
        setTestedChannels(computedChannels);
      }

      return () => {
        setData([]);
        setTestedChannels([]);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (data.length === 0) {
    return <Redirect to={routes.customer.root(Number(customerId))} />;
  }

  return (
    <Box
      sx={{
        height: '100vh',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          flex: '1 1',
          overflow: 'auto',
          px: 4,
          py: 1.5,
        }}
      >
        {data.map((channel, index) => (
          <PreviewIntelbrasChannel
            keyChannel={index + 1}
            channel={channel}
            customerId={customerId}
            key={`${channel.idMask}-${channel.name}`}
          />
        ))}
      </Box>
      <Divider />

      <Box
        sx={{
          height: '360px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flex: '0 0 auto',
            padding: '20px',
            justifyContent: 'flex-end',
            gap: 2,
          }}
        >
          <Form.CancelButton
            isSubmitting={isSubmitting}
            onClick={() => {
              history.push(
                query.get('returnRoute') ||
                  routes.customer.channel.create.formBatchIntelbras(customerId)
              );
              setData([]);
              setTestedChannels([]);
            }}
          />
          <Button
            onClick={handleSave}
            variant="contained"
            aria-label="save preview channels"
            color="primary"
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <CircularProgress size="1.6rem" />
            ) : (
              t('channels:register_channels', {
                number_channels: data[0].qtyChannels,
              })
            )}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
