import { TFunction } from 'i18next';
import { LicenseType } from 'types/license.types';

enum Quality {
  Q720x480 = '480p',
  Q1080x720 = '720p HD',
  Q1280x720 = '720p HD',
  Q1920x1080 = '1080p Full HD',
  Q2560x1440 = '1440p QHD 2K',
  Q3840x2160 = '2160p UHD 4K',
  Q7680x4320 = '4320p UHD 8k',
}
export function formatLicenseDescription(license: LicenseType, t: TFunction): string {
  const { maxResolution, recordingType, retentionTime } = license;

  const query = `Q${maxResolution}`;
  const quality = Quality[(query as keyof typeof Quality) ?? maxResolution];

  const qualityPrefix = t('_common:quality');
  const formatedString = `${qualityPrefix} ${quality}, ${t(
    `channels:recording_type_${recordingType}`
  )}, ${
    retentionTime === 0
      ? t('channels:no_retention_time')
      : t('channels:day_retention', {
          count: retentionTime,
        })
  }`;

  return formatedString;
}
