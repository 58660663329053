import { loggedUserAtom } from 'atoms/users';
import { ErrorBoundaryDefault } from 'components/ErrorBoundary';
import Layout from 'components/Layout';
import { asyncComponent } from 'HOCs';
import { useFetch } from 'hooks';
import React from 'react';
import { useSetRecoilState } from 'recoil';
import { User } from 'types/user.types';

const at15Minutes = 15 * 60 * 1000;

const SetLoggedUser = ({ children }: { children: React.ReactNode }) => {
  const { data: loggedUser } = useFetch<User>('/v1/users/currentUserInfo', {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshInterval: at15Minutes,
    // normalizeData: (data) => ({
    //   ...data,
    //   masterCompany: { ...data.masterCompany, enableLicense: true },
    // }),
  });
  const setLoggedUser = useSetRecoilState(loggedUserAtom);

  React.useEffect(
    function setAtomLoggedUser() {
      setLoggedUser(loggedUser || null);
    },
    [setLoggedUser, loggedUser]
  );

  return <>{children}</>;
};

export default asyncComponent(SetLoggedUser, undefined, (props) => (
  <Layout.Container>
    <ErrorBoundaryDefault {...props} />
  </Layout.Container>
));
