import React from 'react';
import { SvgIcon } from '@mui/material';

function VideoCamCloud() {
  return (
    <SvgIcon
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="mask0_2205_1661" maskUnits="userSpaceOnUse" x="0" y="0" width="33" height="32">
        <rect x="0.5" width="32" height="32" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2205_1661)">
        <mask id="mask1_2205_1661" maskUnits="userSpaceOnUse" x="0" y="12" width="17" height="16">
          <path d="M0.5 12H16.5V28H0.5V12Z" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask1_2205_1661)">
          <path
            d="M5.16536 24.6673C4.23607 24.6673 3.44819 24.3562 2.80173 23.734C2.15526 23.1118 1.83203 22.3534 1.83203 21.459C1.83203 20.7493 2.10223 20.1343 2.64264 19.6142C3.18304 19.0941 3.82193 18.834 4.5593 18.834C5.29668 18.834 5.93557 19.0941 6.47597 19.6142C7.01637 20.1343 7.28658 20.7493 7.28658 21.459H8.4987C8.4987 20.4576 8.17547 19.6191 7.529 18.9434C6.88254 18.2677 6.04415 17.8423 5.01385 17.6673C5.19567 16.9479 5.60728 16.3791 6.2487 15.9611C6.89011 15.543 7.64011 15.334 8.4987 15.334C9.69062 15.334 10.6957 15.7277 11.5138 16.5152C12.332 17.3027 12.7411 18.2701 12.7411 19.4173C13.3775 19.4173 13.9406 19.6895 14.4305 20.234C14.9204 20.7784 15.1654 21.3812 15.1654 22.0423C15.1654 22.7715 14.9002 23.3913 14.3699 23.9017C13.8396 24.4121 13.1957 24.6673 12.4381 24.6673H5.16536Z"
            fill="white"
          />
        </g>
        <path
          d="M21.8346 26.6673H16.068C16.3569 26.2673 16.6069 25.8451 16.818 25.4007C17.0291 24.9562 17.2124 24.4895 17.368 24.0007H21.8346V8.00065H5.83464V12.3673C5.34575 12.5007 4.87908 12.6729 4.43464 12.884C3.99019 13.0951 3.56797 13.3451 3.16797 13.634V8.00065C3.16797 7.26732 3.42908 6.63954 3.9513 6.11732C4.47352 5.5951 5.1013 5.33398 5.83464 5.33398H21.8346C22.568 5.33398 23.1957 5.5951 23.718 6.11732C24.2402 6.63954 24.5013 7.26732 24.5013 8.00065V14.0007L29.8346 8.66732V23.334L24.5013 18.0007V24.0007C24.5013 24.734 24.2402 25.3618 23.718 25.884C23.1957 26.4062 22.568 26.6673 21.8346 26.6673Z"
          fill="white"
        />
      </g>
    </SvgIcon>
  );
}

export default VideoCamCloud;
