import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckCircle, Edit, Error } from '@mui/icons-material';
import { Box, Typography, IconButton } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { Channel } from 'types/channels.types';
import { testedUrlsResultsAtom } from 'atoms/batchChannel';
import ChannelFormDialog from './ChannelFormDialog';

export default function PreviewChannel({
  channel,
  sequential,
}: {
  channel: Channel;
  sequential: number;
}) {
  const { t } = useTranslation();
  const [updateConfirmModalOpen, setUpdateConfirmModalOpen] = useState(false);
  const returnedTestUrl = useRecoilValue(testedUrlsResultsAtom('url'));
  const returnedTestUrlOnlyRecord = useRecoilValue(testedUrlsResultsAtom('urlOnlyRecord'));
  return (
    <Box
      display="flex"
      flexDirection="row"
      component="div"
      m={2}
      p={2}
      sx={{
        borderBottom: '1px solid #464646',
        justifyContent: 'space-between',
      }}
    >
      <Box>
        <Box mb={3}>
          <Typography variant="body2" gutterBottom>
            {t('channels:channel_name')}
          </Typography>
          <Typography variant="body1" gutterBottom component="div">
            {channel.name}
          </Typography>
        </Box>
        <Box display="flex">
          <Box>
            <Typography variant="body2" gutterBottom>
              {t('channels:channel_group')}
            </Typography>
            <Typography variant="body1" gutterBottom component="div">
              {channel?.channelGroup?.name}
            </Typography>
          </Box>
          <Box ml={4}>
            <Typography variant="body2" gutterBottom>
              {t('channels:recording_type')}
            </Typography>
            <Typography variant="body1" gutterBottom component="div">
              {t(
                `channels:recording_type_${
                  channel.channelGroup?.license
                    ? channel.channelGroup.license.licenseType.recordingType
                    : channel.recordingType
                }`
              )}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box>
          {channel.url && (
            <Box mb={4}>
              <Typography component="p">{t('channels:rtsp_url')}</Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {returnedTestUrl[channel.url] === 200 ? (
                  <CheckCircle sx={{ marginRight: 1, color: 'success.main' }} />
                ) : (
                  <Error sx={{ marginRight: 1 }} color="error" />
                )}
                <Typography variant="body2">{channel.url}</Typography>
              </Box>
            </Box>
          )}
          {channel.urlOnlyRecord && (
            <Box>
              <Typography component="p">{t('channels:rtsp_recording_url')}</Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {returnedTestUrlOnlyRecord[channel.urlOnlyRecord] === 200 ? (
                  <CheckCircle sx={{ marginRight: 1, color: 'success.main' }} />
                ) : (
                  <Error sx={{ marginRight: 1 }} color="error" />
                )}
                <Typography variant="body2">{channel.urlOnlyRecord}</Typography>
              </Box>
            </Box>
          )}
        </Box>

        <Box ml={2}>
          <IconButton
            aria-label="edit"
            onClick={() => {
              // history.push(routes.customer.channel.batchPreviewEdit(customerId, sequential));
              setUpdateConfirmModalOpen(true);
            }}
          >
            <Edit />
          </IconButton>
        </Box>
      </Box>
      <ChannelFormDialog
        open={updateConfirmModalOpen}
        channel={channel}
        handleClose={() => {
          setUpdateConfirmModalOpen(false);
        }}
        sequential={sequential}
      />
    </Box>
  );
}
