export enum LicenseRecordingType {
  RECORD_24_7 = 'RECORD_24_7',
  EVENT = 'EVENT',
  SCHEDULE = 'SCHEDULE',
  LIVE = 'LIVE',
}

export enum LicenseNameEnum {
  // Gravação contínua (24/7)
  VGA_247_1 = 'VGA_247_1',
  VGA_247_3 = 'VGA_247_3',
  VGA_247_7 = 'VGA_247_7',
  VGA_247_15 = 'VGA_247_15',
  VGA_247_30 = 'VGA_247_30',
  D1_247_1 = 'D1_247_1',
  D1_247_3 = 'D1_247_3',
  D1_247_7 = 'D1_247_7',
  D1_247_15 = 'D1_247_15',
  D1_247_30 = 'D1_247_30',
  HD_247_1 = 'HD_247_1',
  HD_247_3 = 'HD_247_3',
  HD_247_7 = 'HD_247_7',
  HD_247_15 = 'HD_247_15',
  HD_247_30 = 'HD_247_30',
  FULLHD_247_1 = 'FULLHD_247_1',
  FULLHD_247_3 = 'FULLHD_247_3',
  FULLHD_247_7 = 'FULLHD_247_7',
  FULLHD_247_15 = 'FULLHD_247_15',
  FULLHD_247_30 = 'FULLHD_247_30',

  // Gravação por evento
  VGA_EVENT_1 = 'VGA_EVENT_1',
  VGA_EVENT_3 = 'VGA_EVENT_3',
  VGA_EVENT_7 = 'VGA_EVENT_7',
  VGA_EVENT_15 = 'VGA_EVENT_15',
  VGA_EVENT_30 = 'VGA_EVENT_30',
  D1_EVENT_1 = 'D1_EVENT_1',
  D1_EVENT_3 = 'D1_EVENT_3',
  D1_EVENT_7 = 'D1_EVENT_7',
  D1_EVENT_15 = 'D1_EVENT_15',
  D1_EVENT_30 = 'D1_EVENT_30',
  HD_EVENT_1 = 'HD_EVENT_1',
  HD_EVENT_3 = 'HD_EVENT_3',
  HD_EVENT_7 = 'HD_EVENT_7',
  HD_EVENT_15 = 'HD_EVENT_15',
  HD_EVENT_30 = 'HD_EVENT_30',
  FULLHD_EVENT_1 = 'FULLHD_EVENT_1',
  FULLHD_EVENT_3 = 'FULLHD_EVENT_3',
  FULLHD_EVENT_7 = 'FULLHD_EVENT_7',
  FULLHD_EVENT_15 = 'FULLHD_EVENT_15',
  FULLHD_EVENT_30 = 'FULLHD_EVENT_30',

  // Somente ao vivo
  VGA_LIVE = 'VGA_LIVE',
  D1_LIVE = 'D1_LIVE',
  HD_LIVE = 'HD_LIVE',
  FULLHD_LIVE = 'FULLHD_LIVE',

  // Agenda de gravação
  VGA_SCHEDULED_1 = 'VGA_SCHEDULED_1',
  VGA_SCHEDULED_3 = 'VGA_SCHEDULED_3',
  VGA_SCHEDULED_7 = 'VGA_SCHEDULED_7',
  VGA_SCHEDULED_15 = 'VGA_SCHEDULED_15',
  VGA_SCHEDULED_30 = 'VGA_SCHEDULED_30',
  D1_SCHEDULED_1 = 'D1_SCHEDULED_1',
  D1_SCHEDULED_3 = 'D1_SCHEDULED_3',
  D1_SCHEDULED_7 = 'D1_SCHEDULED_7',
  D1_SCHEDULED_15 = 'D1_SCHEDULED_15',
  D1_SCHEDULED_30 = 'D1_SCHEDULED_30',
  HD_SCHEDULED_1 = 'HD_SCHEDULED_1',
  HD_SCHEDULED_3 = 'HD_SCHEDULED_3',
  HD_SCHEDULED_7 = 'HD_SCHEDULED_7',
  HD_SCHEDULED_15 = 'HD_SCHEDULED_15',
  HD_SCHEDULED_30 = 'HD_SCHEDULED_30',
  FULLHD_SCHEDULED_1 = 'FULLHD_SCHEDULED_1',
  FULLHD_SCHEDULED_3 = 'FULLHD_SCHEDULED_3',
  FULLHD_SCHEDULED_7 = 'FULLHD_SCHEDULED_7',
  FULLHD_SCHEDULED_15 = 'FULLHD_SCHEDULED_15',
  FULLHD_SCHEDULED_30 = 'FULLHD_SCHEDULED_30',
}

export interface LicenseType {
  name: LicenseNameEnum;
  recordingType: LicenseRecordingType;
  retentionTime: number;
  maxResolution: string;
}
export interface License {
  consumedChannels: number;
  name: string | null;
  id: number;
  description: string | null;
  enable: boolean;
  maxAllowedChannels: number;
  licenseType: LicenseType;
}
