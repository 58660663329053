import { atom, atomFamily } from 'recoil';

export const channelSelectedAtom = atom<number | null>({
  key: 'channelSelected',
  default: null,
});

export const onlineChannelAtom = atomFamily<boolean, number>({
  key: 'onlineChannelAtom',
  default: false,
});
