import React from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { Form } from 'components/core';
import { useTranslation } from 'react-i18next';
import { Box, Button, CircularProgress, Divider } from '@mui/material';
import routes from 'helpers/routes';
import { Channel } from 'types/channels.types';
import { useChannelsApi } from 'hooks/channels/useChannelsApi';
import { BatchRTMPCopyModal } from './BatchRTMPCopyModal';
import PreviewChannel from './PreviewChannel';

interface Props {
  customerId: string;
  channels: Channel[];
  onClose: () => void;
}
export function PreviewPage({ customerId, channels, onClose }: Props) {
  const history = useHistory();
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const { createBatchChannels } = useChannelsApi(Number(customerId));
  const [channelsCreated, setChannelsCreated] = React.useState<Channel[] | null>();
  const [modalOpen, setModalOpen] = React.useState(false);

  const handleSave = React.useCallback(async () => {
    try {
      setIsSubmitting(true);
      const data = await createBatchChannels(channels);
      setChannelsCreated(data);
      setIsSubmitting(false);

      if (data?.some((channel) => channel.url.startsWith('rtmp'))) {
        setModalOpen(true);
        return;
      }

      history.push(routes.customer.root(Number(customerId)));
    } catch (error) {
      setIsSubmitting(false);
      console.error(error);
    }
  }, [createBatchChannels, channels, history, customerId]);

  if (channels.length === 0) {
    return <Redirect to={routes.customer.root(Number(customerId))} />;
  }

  return (
    <Box
      sx={{
        height: '100vh',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          flex: '1 1',
          overflow: 'auto',
          px: 4,
          py: 1.5,
        }}
      >
        {channels.map((channel, index) => (
          <PreviewChannel
            // eslint-disable-next-line react/no-array-index-key
            key={index + 1}
            sequential={index + 1}
            channel={channel}
          />
        ))}
      </Box>
      <Divider />

      <Box
        sx={{
          height: '360px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flex: '0 0 auto',
            padding: '20px',
            justifyContent: 'flex-end',
            gap: 2,
          }}
        >
          <Form.CancelButton isSubmitting={isSubmitting} onClick={onClose} />
          <Button
            onClick={handleSave}
            variant="contained"
            aria-label="save preview channels"
            color="primary"
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <CircularProgress size="1.6rem" />
            ) : (
              t('channels:register_channels', {
                number_channels: channels[0].qtyChannels,
              })
            )}
          </Button>
        </Box>
      </Box>
      {channelsCreated?.length &&
        channelsCreated?.some((channel) => channel.url.startsWith('rtmp')) && (
          <BatchRTMPCopyModal
            open={modalOpen}
            setOpen={setModalOpen}
            channels={channelsCreated}
            onRedirect={() => {
              history.push(routes.customer.root(Number(customerId)));
            }}
          />
        )}
    </Box>
  );
}
