/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import Hlsjs, { ErrorTypes, Events, isSupported } from 'hls.js';

const playlistStatusMap = new Map();
const fragmentStatusMap = new Map();
const latencyMap = new Map();

function updateFragmentStatusMap(channelId, status) {
  fragmentStatusMap.set(channelId, status);
}

function updatePlaylistStatusMap(channelId, status) {
  playlistStatusMap.set(channelId, status);
}

function updateLatencyMap(channelId, latency) {
  latencyMap.set(channelId, latency);
}

function getLatencyByChannelId(channelId) {
  return latencyMap.get(channelId);
}

function getChannelOnlineById(channelId) {
  return fragmentStatusMap.get(channelId);
}

function getStatusPlaylistByChannelId(channelId) {
  return playlistStatusMap.get(channelId);
}

let currentId = null;
let lastSegmentTimestamp = null;

function handleUpdateFragmentStatus(id, status) {
  if (!id) {
    console.error('Channel ID não encontrado na URL durante a atualização do status do fragmento');
    return;
  }

  updateFragmentStatusMap(id, status);
  currentId = id;
  lastSegmentTimestamp = Date.now();
}

function handleUpdatePlaylistStatus(id, status) {
  if (!id) {
    console.error('Channel ID não encontrado na URL durante a atualização do status da playlist');
    return;
  }
  updatePlaylistStatusMap(id, status);
}

function handleUpdateLatency(id, latency) {
  if (!id) {
    console.error('Channel ID não encontrado na URL durante a extração da latência');
    return;
  }

  updateLatencyMap(id, latency);
}

function registerSourceHandler(videojs) {
  const hooks = {};
  const inactivityThreshold = 5000; // Tempo em milissegundos (5 segundos)

  function checkInactivity() {
    const currentTime = Date.now();
    if (currentTime - lastSegmentTimestamp > inactivityThreshold) {
      if (currentId) {
        console.info('The fragment consumption has stopped.... ', currentId);
        handleUpdateFragmentStatus(currentId, 204);
      }
    }
    // console.warn('Consumindo fragmentos...');
  }

  function Html5Hlsjs(source, tech) {
    tech.name_ = 'StreamrootHlsjs';

    const _video = tech.el();
    let _hls;
    const _errorCounts = {};
    let _duration = null;
    let _metadata = null;
    let _isLive = null;
    let _dvrDuration = null;
    let _edgeMargin = null;
    let _hlsjsConfig = null;
    const _player = videojs(tech.options_.playerId);
    let qualityLevels = _player.qualityLevels && _player.qualityLevels();

    const inactivityCheckInterval = setInterval(checkInactivity, 5000);

    function stopInactivityCheck() {
      clearInterval(inactivityCheckInterval);
    }

    // The `videojs-hls-quality-selector` plugin only activates when tech.hls is defined.
    if (qualityLevels && _player.hlsQualitySelector) {
      tech.hls = {};
    }

    let _uiTextTrackHandled = false;

    function _executeHooksFor(type) {
      if (hooks[type] === undefined) {
        return;
      }

      // ES3 and IE < 9
      for (let i = 0; i < hooks[type].length; i++) {
        hooks[type][i](_player, _hls);
      }
    }

    function _handleMediaError(error) {
      if (_errorCounts[ErrorTypes.MEDIA_ERROR] === 1) {
        console.info('trying to recover media error');
        _hls.recoverMediaError();
      } else if (_errorCounts[ErrorTypes.MEDIA_ERROR] === 2) {
        console.info('2nd try to recover media error (by swapping audio codec');
        _hls.swapAudioCodec();
        _hls.recoverMediaError();
      } else if (_errorCounts[ErrorTypes.MEDIA_ERROR] > 2) {
        console.error('Media error occurred:', error.message);
        tech.error = () => error;
        tech.trigger('error');
      }
    }

    function getChannelId(url) {
      return url.match(/\/live\/playlist\/(\d+)\//)[1];
    }

    function _onError(data) {
      const hlsError = {
        message: `HLS.js error: ${data.type} - fatal: ${data.fatal} - ${
          data.details
        } - occuredAt: ${new Date().toLocaleString()}`,
        resume: {
          channelId: data.url ? getChannelId(data.url) : undefined,
          type: data.type,
          fatal: data.fatal,
          reason: data.reason ? data.reason : data.error.message,
          details: data.details ? data.details : undefined,
          status: data.response?.code,
          ocurredAt: new Date().toLocaleString(),
        },
      };

      handleUpdatePlaylistStatus(hlsError.resume.channelId, hlsError.resume.status);

      console.error(hlsError.message);

      // increment/set error count
      if (_errorCounts[data.type]) {
        _errorCounts[data.type] += 1;
      } else {
        _errorCounts[data.type] = 1;
      }

      // implement simple error handling based on hls.js documentation (https://github.com/dailymotion/hls.js/blob/master/API.md#fifth-step-error-handling)
      if (data.fatal) {
        switch (data.type) {
          case ErrorTypes.NETWORK_ERROR:
            console.error('Network error occurred:', hlsError.message);
            hlsError.code = 2;
            tech.error = () => hlsError;
            tech.trigger('error');
            break;

          case ErrorTypes.MEDIA_ERROR:
            hlsError.code = 3;
            _handleMediaError(hlsError);
            break;

          default:
            // cannot recover
            _hls.destroy();
            console.error('Unhandled fatal error:', hlsError.message);
            tech.error = () => hlsError;
            tech.trigger('error');
            break;
        }
      } else if (
        data.type === ErrorTypes.MEDIA_ERROR &&
        _errorCounts[ErrorTypes.MEDIA_ERROR] % 3 === 0
      ) {
        console.warn('Non-fatal media error occurred:', hlsError.message);
        tech.error = () => ({
          error: hlsError.message,
          ...data,
          errorCount: _errorCounts[data.type],
        });
        tech.trigger('errorNonFatal');
      }
    }

    function switchQuality(qualityId) {
      _hls.nextLevel = qualityId;
    }

    function _levelLabel(level) {
      if (level.height) return `${level.height}p`;
      if (level.width) return `${Math.round((level.width * 9) / 16)}p`;
      if (level.bitrate) return `${level.bitrate / 1000}kbps`;
      return 0;
    }

    function _relayQualityChange() {
      // Determine if it is "Auto" (all tracks enabled)
      let isAuto = true;
      for (let i = 0; i < qualityLevels.length; i++) {
        if (!qualityLevels[i]._enabled) {
          isAuto = false;
          break;
        }
      }

      // Interact with ME
      if (isAuto) {
        _hls.currentLevel = -1;
      } else {
        // Find ID of highest enabled track
        let selectedTrack;
        for (selectedTrack = qualityLevels.length - 1; selectedTrack >= 0; selectedTrack--) {
          if (qualityLevels[selectedTrack]._enabled) {
            break;
          }
        }
        _hls.currentLevel = selectedTrack;
      }
    }

    function _toggleLevel(level, toggle) {
      // NOTE: Brightcove switcher works TextTracks-style (enable tracks that it wants to ABR on)
      if (qualityLevels) {
        if (typeof toggle === 'boolean') {
          qualityLevels[level]._enabled = toggle;
          _relayQualityChange();
        }
        return qualityLevels[level]._enabled;
      }
      return false;
    }

    function _handleQualityLevels() {
      if (_metadata) {
        qualityLevels = _player.qualityLevels && _player.qualityLevels();
        if (qualityLevels) {
          tech.hls = {};
          for (let i = 0; i < _metadata.levels.length; i++) {
            const details = _metadata.levels[i];
            const name = `hlsjs-${i}`;
            const representation = {
              id: name,
              label: name,
              width: details.width,
              height: details.height,
              bandwidth: details.bitrate,
              bitrate: details.bitrate,
              _enabled: false,
            };
            representation.enabled = _toggleLevel.bind(this, i);
            qualityLevels.addQualityLevel(representation);
          }
        }
      }
    }

    function _syncQualityLevels(event, data) {
      if (qualityLevels) {
        qualityLevels.selectedIndex_ = data.level;
        qualityLevels.trigger({
          selectedIndex: data.level,
          type: 'change',
        });
      }
    }

    function _notifyVideoQualities() {
      if (_metadata) {
        const cleanTracklist = [];

        if (_metadata.levels.length > 1) {
          const autoLevel = {
            id: -1,
            label: 'auto',
            selected: _hls.manualLevel === -1,
          };
          cleanTracklist.push(autoLevel);
        }

        _metadata.levels.forEach((level, index) => {
          const quality = {}; // Don't write in level (shared reference with Hls.js)
          quality.id = index;
          quality.selected = index === _hls.manualLevel;
          quality.label = _levelLabel(level);

          cleanTracklist.push(quality);
        });

        const payload = {
          qualityData: { video: cleanTracklist },
          qualitySwitchCallback: switchQuality,
        };

        tech.trigger('loadedqualitydata', payload);

        // Self-de-register so we don't raise the payload multiple times
        _video.removeEventListener('playing', _notifyVideoQualities);
      }
    }

    function _updateSelectedAudioTrack() {
      const playerAudioTracks = tech.audioTracks();
      for (let j = 0; j < playerAudioTracks.length; j++) {
        if (playerAudioTracks[j].enabled) {
          _hls.audioTrack = j;
          break;
        }
      }
    }

    function _onAudioTracks() {
      const hlsAudioTracks = _hls.audioTracks;
      const playerAudioTracks = tech.audioTracks();
      if (hlsAudioTracks.length > 1 && playerAudioTracks.length === 0) {
        // Add Hls.js audio tracks if not added yet
        for (let i = 0; i < hlsAudioTracks.length; i++) {
          playerAudioTracks.addTrack(
            new videojs.AudioTrack({
              id: i,
              kind: 'alternative',
              label: hlsAudioTracks[i].name || hlsAudioTracks[i].lang,
              language: hlsAudioTracks[i].lang,
              enabled: i === _hls.audioTrack,
            })
          );
        }

        // Handle audio track change event
        playerAudioTracks.addEventListener('change', _updateSelectedAudioTrack);
      }
    }

    function _getTextTrackLabel(textTrack) {
      // NOTE: label here is readable label and is optional (used in the UI so if it is there it should be different)
      const label = textTrack.label ? textTrack.label : textTrack.language;
      return label;
    }

    function _isSameTextTrack(track1, track2) {
      return (
        _getTextTrackLabel(track1) === _getTextTrackLabel(track2) && track1.kind === track2.kind
      );
    }

    function _updateSelectedTextTrack() {
      const playerTextTracks = _player.textTracks();
      let activeTrack = null;
      for (let j = 0; j < playerTextTracks.length; j++) {
        if (playerTextTracks[j].mode === 'showing') {
          activeTrack = playerTextTracks[j];
          break;
        }
      }

      const hlsjsTracks = _video.textTracks;
      for (let k = 0; k < hlsjsTracks.length; k++) {
        if (hlsjsTracks[k].kind === 'subtitles' || hlsjsTracks[k].kind === 'captions') {
          hlsjsTracks[k].mode =
            activeTrack && _isSameTextTrack(hlsjsTracks[k], activeTrack) ? 'showing' : 'disabled';
        }
      }
    }

    function _startLoad() {
      _hls.startLoad(-1);
      _video.removeEventListener('play', _startLoad);
    }

    function _oneLevelObjClone(obj) {
      const result = {};
      const objKeys = Object.keys(obj);
      for (let i = 0; i < objKeys.length; i++) {
        result[objKeys[i]] = obj[objKeys[i]];
      }
      return result;
    }

    function _filterDisplayableTextTracks(textTracks) {
      const displayableTracks = [];

      // Filter out tracks that is displayable (captions or subtitltes)
      for (let idx = 0; idx < textTracks.length; idx++) {
        if (textTracks[idx].kind === 'subtitles' || textTracks[idx].kind === 'captions') {
          displayableTracks.push(textTracks[idx]);
        }
      }

      return displayableTracks;
    }

    function _updateTextTrackList() {
      const displayableTracks = _filterDisplayableTextTracks(_video.textTracks);
      const playerTextTracks = _player.textTracks();

      // Add stubs to make the caption switcher shows up
      // NOTE: Adding the Hls.js text track in will make us have double captions
      for (let idx = 0; idx < displayableTracks.length; idx++) {
        let isAdded = false;
        for (let jdx = 0; jdx < playerTextTracks.length; jdx++) {
          if (_isSameTextTrack(displayableTracks[idx], playerTextTracks[jdx])) {
            isAdded = true;
            break;
          }
        }

        if (!isAdded) {
          const hlsjsTextTrack = displayableTracks[idx];
          _player.addRemoteTextTrack(
            {
              kind: hlsjsTextTrack.kind,
              label: _getTextTrackLabel(hlsjsTextTrack),
              language: hlsjsTextTrack.language,
              srclang: hlsjsTextTrack.language,
            },
            false
          );
        }
      }

      // Handle UI switching
      _updateSelectedTextTrack();
      if (!_uiTextTrackHandled) {
        playerTextTracks.addEventListener('change', _updateSelectedTextTrack);
        _uiTextTrackHandled = true;
      }
    }

    function _onMetaData(event, data) {
      // This could arrive before 'loadedqualitydata' handlers is registered, remember it so we can raise it later
      _metadata = data;
      _handleQualityLevels();
    }

    function _createCueHandler(captionConfig) {
      return {
        newCue: (track, startTime, endTime, captionScreen) => {
          let row;
          let cue;
          let text;
          const VTTCue = window.VTTCue || window.TextTrackCue;

          for (let r = 0; r < captionScreen.rows.length; r++) {
            row = captionScreen.rows[r];
            text = '';
            if (!row.isEmpty()) {
              for (let c = 0; c < row.chars.length; c++) {
                text += row.chars[c].uchar;
              }
              cue = new VTTCue(startTime, endTime, text.trim());

              // NOTE: typeof null === 'object'
              if (captionConfig != null && typeof captionConfig === 'object') {
                // Copy client overridden property into the cue object
                const configKeys = Object.keys(captionConfig);
                for (let k = 0; k < configKeys.length; k++) {
                  cue[configKeys[k]] = captionConfig[configKeys[k]];
                }
              }
              track.addCue(cue);
              if (endTime === startTime) track.addCue(new VTTCue(endTime + 5, ''));
            }
          }
        },
      };
    }

    function _initHlsjs() {
      const hlsjsConfigRef =
        (_player.srOptions_ && _player.srOptions_.hlsjsConfig) || tech.options_.hlsjsConfig;
      // NOTE: Hls.js will write to the reference thus change the object for later streams
      _hlsjsConfig = hlsjsConfigRef ? _oneLevelObjClone(hlsjsConfigRef) : {};

      if (
        ['', 'auto'].indexOf(_video.preload) === -1 &&
        !_video.autoplay &&
        _hlsjsConfig.autoStartLoad === undefined
      ) {
        _hlsjsConfig.autoStartLoad = false;
      }

      const captionConfig =
        (_player.srOptions_ && _player.srOptions_.captionConfig) || tech.options_.captionConfig;
      if (captionConfig) {
        _hlsjsConfig.cueHandler = _createCueHandler(captionConfig);
      }

      // If the user explicitely sets autoStartLoad to false, we're not going to enter the if block above, that's why we have a separate if block here to set the 'play' listener
      if (_hlsjsConfig.autoStartLoad === false) {
        _video.addEventListener('play', _startLoad);
      }

      // _notifyVideoQualities sometimes runs before the quality picker event handler is registered -> no video switcher
      _video.addEventListener('playing', _notifyVideoQualities);

      _hls = new Hlsjs(_hlsjsConfig);

      _executeHooksFor('beforeinitialize');

      _hls.on(Events.ERROR, (event, data) => {
        _onError(data);
      });
      // _hls.on(Events.MANIFEST_LOADED, (event, data) => {});
      _hls.on(Events.LEVEL_UPDATED, (event, data) => {
        // Events.LEVEL_UPDATED, is trigged when playlist loads succesfuly
        const channelId = getChannelId(data.details.url);
        const status = data.details.m3u8 ? 200 : 204;
        handleUpdatePlaylistStatus(channelId, status);
      });
      _hls.on(Events.AUDIO_TRACKS_UPDATED, _onAudioTracks);
      _hls.on(Events.MANIFEST_PARSED, _onMetaData);
      _hls.on(Events.LEVEL_LOADED, (event, data) => {
        // The DVR plugin will auto seek to "live edge" on start up
        if (_hlsjsConfig.liveSyncDuration) {
          _edgeMargin = _hlsjsConfig.liveSyncDuration;
        } else if (_hlsjsConfig.liveSyncDurationCount) {
          _edgeMargin = _hlsjsConfig.liveSyncDurationCount * data.details.targetduration;
        }
        _isLive = data.details.live;
        _dvrDuration = data.details.totalduration;
        _duration = _isLive ? Infinity : data.details.totalduration;
      });
      _hls.on(Events.FRAG_BUFFERED, (eventName, data) => {
        /*
         * Para calcular o atraso do vídeo (delay) no HLS.js,
         * é necessário armazenar o tempo de início de reprodução do
         * fragmento atual e compará-lo com o tempo de início de carregamento do fragmento.
         * A diferença entre esses dois tempos representa o atraso do vídeo (delay).
         */

        const channelId = getChannelId(data.frag.baseurl);

        handleUpdateLatency(
          channelId,
          Number((data.stats.loading.first - data.stats.loading.start).toFixed(0))
        );
      });
      _hls.on(Events.FRAG_PARSING_INIT_SEGMENT, (eventName, data) => {
        // console.log(eventName, data);
      });
      _hls.on(Events.FRAG_LOAD_EMERGENCY_ABORTED, (eventName, data) => {
        console.log(eventName, data);
      });
      _hls.on(Events.FRAG_LOADING, (eventName, data) => {
        // console.log(eventName, data);
      });
      _hls.on(Events.FRAG_LOADED, (_, data) => {
        // Emit custom 'loadedmetadata' event for parity with `videojs-contrib-hls`
        // Ref: https://github.com/videojs/videojs-contrib-hls#loadedmetadata
        tech.trigger('loadedmetadata');

        const channelId = getChannelId(data.frag.baseurl);
        const { status } = data.networkDetails;
        handleUpdateFragmentStatus(channelId, status);
      });
      _hls.on(Events.LEVEL_SWITCHED, _syncQualityLevels);

      _hls.attachMedia(_video);
      _video.textTracks.addEventListener('addtrack', _updateTextTrackList);
      _hls.loadSource(source.src);
    }

    function initialize() {
      _initHlsjs();
    }

    this.duration = () => _duration || _video.duration || 0;

    this.seekable = () => {
      if (_hls.media) {
        if (!_isLive) {
          return videojs.time.createTimeRanges(0, _hls.media.duration);
        }
        // Video.js doesn't seem to like floating point timeranges
        const startTime = Math.round(_hls.media.duration - _dvrDuration);
        const endTime = Math.round(_hls.media.duration - _edgeMargin);
        return videojs.time.createTimeRanges(startTime, endTime);
      }
      return videojs.time.createTimeRanges();
    };

    // See comment for `initialize` method.
    this.dispose = () => {
      _video.removeEventListener('play', _startLoad);
      _video.textTracks.removeEventListener('addtrack', _updateTextTrackList);
      _video.removeEventListener('playing', _notifyVideoQualities);

      _player.textTracks().removeEventListener('change', _updateSelectedTextTrack);
      _uiTextTrackHandled = false;
      _player.audioTracks().removeEventListener('change', _updateSelectedAudioTrack);

      _hls.destroy();

      stopInactivityCheck();
    };

    _video.addEventListener('error', (evt) => {
      let errorTxt;
      const mediaError = evt.currentTarget.error;

      switch (mediaError.code) {
        case mediaError.MEDIA_ERR_ABORTED:
          errorTxt = 'You aborted the video playback';
          break;
        case mediaError.MEDIA_ERR_DECODE:
          errorTxt =
            'The video playback was aborted due to a corruption problem or because the video used features your browser did not support';
          _handleMediaError(mediaError);
          break;
        case mediaError.MEDIA_ERR_NETWORK:
          errorTxt = 'A network error caused the video download to fail part-way';
          break;
        case mediaError.MEDIA_ERR_SRC_NOT_SUPPORTED:
          errorTxt =
            'The video could not be loaded, either because the server or network failed or because the format is not supported';
          break;

        default:
          errorTxt = mediaError.message;
      }

      console.error('MEDIA_ERROR: ', errorTxt);
    });

    initialize();
  }

  Html5Hlsjs.addHook = (type, callback) => {
    hooks[type] = hooks[type] || [];
    hooks[type].push(callback);
  };

  Html5Hlsjs.removeHook = (type, callback) => {
    if (hooks[type] === undefined) {
      return false;
    }

    const index = hooks[type].indexOf(callback);

    if (index === -1) {
      return false;
    }

    hooks[type].splice(index, 1);

    return true;
  };

  if (isSupported()) {
    let html5;

    if (typeof videojs.getTech === 'function') {
      html5 = videojs.getTech('Html5');
    } else if (typeof videojs.getComponent === 'function') {
      html5 = videojs.getComponent('Html5');
    } else {
      console.error('Not supported version if video.js');

      return;
    }

    if (!html5) {
      console.error('Not supported version if video.js');

      return;
    }

    html5.registerSourceHandler(
      {
        canHandleSource: (source) => {
          const hlsTypeRE = /^(?:application\/x-mpegURL|application\/vnd\.apple\.mpegurl)$/i;
          const hlsExtRE = /\.m3u8/i;
          let result;

          if (hlsTypeRE.test(source.type)) {
            result = 'probably';
          } else if (hlsExtRE.test(source.src)) {
            result = 'maybe';
          } else {
            result = '';
          }

          return result;
        },
        handleSource: (source, tech) => {
          if (tech.hlsProvider) {
            tech.hlsProvider.dispose();
          }

          tech.hlsProvider = new Html5Hlsjs(source, tech);

          return tech.hlsProvider;
        },
      },
      0
    );

    videojs.Html5Hlsjs = Html5Hlsjs;
  } else {
    console.warn('Hls.js is not supported in this browser!');
  }
}

function streamrootHlsjsConfigHandler(options) {
  const player = this;

  if (!options) {
    return;
  }

  if (!player.srOptions_) {
    player.srOptions_ = {};
  }

  if (!player.srOptions_.hlsjsConfig) {
    player.srOptions_.hlsjsConfig = options.hlsjsConfig;
  }

  if (!player.srOptions_.captionConfig) {
    player.srOptions_.captionConfig = options.captionConfig;
  }
}

function registerConfigPlugin(videojs) {
  // Used in Brightcove since we don't pass options directly there
  const registerVjsPlugin = videojs.registerPlugin || videojs.plugin;
  registerVjsPlugin('streamrootHls', streamrootHlsjsConfigHandler);
}

export {
  registerSourceHandler,
  registerConfigPlugin,
  getLatencyByChannelId,
  getChannelOnlineById,
  getStatusPlaylistByChannelId,
};
