import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'components/core';
import { Typography, Box, Divider } from '@mui/material';
import { useYupObject } from 'hooks';
import { PermissionRole } from 'types/user.types';
import { useHavePermission } from 'hooks/useHavePermission';
import { Formik } from 'formik';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useHistory } from 'react-router-dom';
import routes from 'helpers/routes';
import { rtmpChannelsAtom } from 'atoms/rtmp';
import {
  BatchChannelInitialValues,
  Channel,
  ProtocolType,
  RecordingType,
} from 'types/channels.types';
import { ChannelForm } from 'components/Channel';
import { loggedUserAtom } from 'atoms/users';

interface Props {
  initialValues: BatchChannelInitialValues | Channel;
  showDeleteButton?: boolean;
  doDelete?: (id: number) => Promise<void>;
  onClose: () => void;
  customerId: number;
}

export function BatchRTMPForm({
  initialValues,
  showDeleteButton,
  doDelete,
  onClose,
  customerId,
}: Props) {
  const yup = useYupObject();
  const history = useHistory();
  const { t } = useTranslation();
  const excludePermission = useHavePermission(PermissionRole.REMOVE_CHANNEL);

  const setBatchRTMPChannels = useSetRecoilState(rtmpChannelsAtom);
  const loggedUser = useRecoilValue(loggedUserAtom);

  const validationSchema = yup.object({
    qtyChannels: yup.number().max(100).min(2).integer().required(),
    status: yup.bool(),
    name: yup.string().trim().max(100).min(3).required().noSpecialCharacters(),
    channelGroup: yup
      .object({
        id: yup.number().required(),
        label: yup.string().required(),
      })
      .required(),
    recordingType: yup.string().oneOf(Object.values(RecordingType)).required(),
    retentionTime: yup.number().when('recordingType', {
      is: (recordingType) => recordingType !== RecordingType.LIVE,
      then: yup.number().max(9999).min(1).integer().required(),
      otherwise: yup.number(),
    }),
    channelPrivate: yup.bool(),
    activeTransmissionCreditLimit: yup.bool(),
    transmissionCreditLimit: yup
      .number()
      .max(100000000)
      .when('activeTransmissionCreditLimit', {
        is: true,
        then: yup.number().min(1).required(),
        otherwise: yup.number().min(0).required(),
      }),
    activeStorageLimit: yup.bool(),
    storageLimit: yup
      .number()
      .max(1000000)
      .when('activeStorageLimit', {
        is: true,
        then: yup.number().min(1).required(),
        otherwise: yup.number().min(0).required(),
      }),
    simpleScheduledRecording: yup
      .object({
        id: yup.number(),
        name: yup.string(),
      })
      .nullable(),
  });

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        ...initialValues,
        channelGroup: initialValues.channelGroup && {
          label: initialValues.channelGroup.name,
          ...initialValues.channelGroup,
        },
      }}
      onSubmit={async (values) => {
        const isScheduleLinked = Boolean(
          initialValues.recordingType === RecordingType.SCHEDULE &&
            initialValues.simpleScheduledRecording?.id
        );

        const hasUnlinkedSchedule =
          isScheduleLinked && values.recordingType !== RecordingType.SCHEDULE;

        setBatchRTMPChannels(
          Array.from({ length: values.qtyChannels }).map((_, index) => {
            const channel = {
              ...values,
              name: `${values.name} ${index + 1}`,
              channelGroup: values.channelGroup
                ? {
                    id: values.channelGroup.id,
                    name: values.channelGroup.label,
                    license: values.channelGroup.license,
                    totalChannels: values.channelGroup.totalChannels,
                  }
                : undefined,
              connectionTypeEnum: ProtocolType.RTMP,
              simpleScheduledRecording: hasUnlinkedSchedule
                ? null
                : values.simpleScheduledRecording,
            };
            return channel as Channel;
          })
        );
        history.push(routes.customer.channel.create.rtmpChannelsPreview(customerId));
      }}
    >
      {(formik) => (
        <form
          aria-label="channel"
          style={{
            flex: '1 1 auto',
            display: 'flex',
            flexDirection: 'column',
          }}
          onSubmit={formik.handleSubmit}
        >
          <Box
            sx={{
              flex: '1 1 auto',
              py: 1.5,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                marginBottom: '0.6rem',
                fontSize: '16px',
              }}
            >
              {t('channels:information')}
            </Typography>

            <ChannelForm.StatusInput />

            <Box
              sx={{
                display: 'flex',
                marginBottom: '1.2rem',
                gap: '1.2rem',
              }}
            >
              <ChannelForm.QtyChannelsInput protocolValue={ProtocolType.RTMP} />
              <ChannelForm.NameInput />
            </Box>

            <Box
              sx={{
                marginBottom: '1.2rem',
              }}
              display="flex"
            >
              <ChannelForm.ChannelGroupSelect />
              <ChannelForm.RecordingTypeSelect />
              <ChannelForm.RetentionTimeInput />
            </Box>
            <Box>
              <ChannelForm.EnablePreAlarmInput />
            </Box>
            {!loggedUser?.masterCompany.enableLicense && (
              <Box>
                <Box display="flex">
                  <ChannelForm.CreditLimitCheckBox />
                  <ChannelForm.StorageLimitCheckBox />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    width: '100%',
                    mb: 4,
                  }}
                >
                  <ChannelForm.CreditLimitInput />
                  <ChannelForm.StorageLimitInput />
                </Box>
              </Box>
            )}
            <ChannelForm.PrivateChannel />
            <ChannelForm.RecordingScheduleSelect />
          </Box>
          <Divider />
          <Box
            sx={{
              flex: '0 0 auto',
              padding: '20px',
              textAlign: 'end',
            }}
          >
            {showDeleteButton && excludePermission && (
              <Form.DeleteButton
                isSubmitting={formik.isSubmitting}
                confirmModalDescription={t(
                  'channels:not_possible_retrieve_information_and_view_image_camera'
                )}
                confirmModalTitle={t('channels:want_delete')}
                onConfirmDelete={async (setIsDeleting) => {
                  try {
                    if (!doDelete) return;
                    if (!initialValues.id) return;
                    await doDelete(initialValues.id);
                    setIsDeleting(false);
                    onClose();
                  } catch (error) {
                    console.error(error);
                    setIsDeleting(false);
                  }
                }}
              />
            )}

            <Form.CancelButton isSubmitting={formik.isSubmitting} onClick={onClose} />
            <Form.SaveButton
              isSubmitting={formik.isSubmitting}
              initialValues={formik.initialValues}
              values={formik.values}
              disabled={!formik.isValid || !formik.dirty}
              label={t('_common:next')}
            />
          </Box>
        </form>
      )}
    </Formik>
  );
}
