import { CompanySimplifyListItem } from './companies.types';

export enum PermissionGroup {
  ADMIN = 'ADMIN',
  OPERATOR = 'OPERATOR',
  OPERATOR_LOW = 'OPERATOR_LOW',
  COMMERCIAL = 'COMMERCIAL',
  CLIENT = 'CLIENT',
  SUPERVISOR = 'SUPERVISOR',
}

export enum PermissionRole {
  ADMIN = 'ADMIN',
  EDIT_LICENSE = 'EDIT_LICENSE',
  VIEW_LICENSE = 'VIEW_LICENSE',
  INSERT_NETWORK_CONNECTION_PERFORMANCE = 'INSERT_NETWORK_CONNECTION_PERFORMANCE',
  REMOVE_CHANNEL = 'REMOVE_CHANNEL',
  INSERT_PLAYLIST = 'INSERT_PLAYLIST',
  REMOVE_CLIENT_GROUP = 'REMOVE_CLIENT_GROUP',
  EDIT_USER = 'EDIT_USER',
  REMOVE_USER = 'REMOVE_USER',
  VIEW_USER = 'VIEW_USER',
  VIEW_CLIENT = 'VIEW_CLIENT',
  VIEW_CHANNEL_GROUP = 'VIEW_CHANNEL_GROUP',
  EDIT_CHANNEL_GROUP = 'EDIT_CHANNEL_GROUP',
  REMOVE_CLIENT = 'REMOVE_CLIENT',
  INSERT_CLIENT_GROUP = 'INSERT_CLIENT_GROUP',
  REMOVE_CHANNEL_GROUP = 'REMOVE_CHANNEL_GROUP',
  EDIT_MOSAIC = 'EDIT_MOSAIC',
  INSERT_CHANNEL = 'INSERT_CHANNEL',
  INSERT_MOSAIC = 'INSERT_MOSAIC',
  EDIT_CLIENT = 'EDIT_CLIENT',
  INSERT_CLIENT = 'INSERT_CLIENT',
  VIEW_MOSAIC = 'VIEW_MOSAIC',
  REMOVE_MOSAIC = 'REMOVE_MOSAIC',
  VIEW_PLAYLIST = 'VIEW_PLAYLIST',
  EDIT_PLAYLIST = 'EDIT_PLAYLIST',
  VIEW_CLIENT_GROUP = 'VIEW_CLIENT_GROUP',
  EDIT_CHANNEL = 'EDIT_CHANNEL',
  INSERT_CHANNEL_GROUP = 'INSERT_CHANNEL_GROUP',
  VIEW_CHANNEL = 'VIEW_CHANNEL',
  INSERT_USER = 'INSERT_USER',
  EDIT_CLIENT_GROUP = 'EDIT_CLIENT_GROUP',
  REMOVE_PLAYLIST = 'REMOVE_PLAYLIST',
  INSERT_COMPANY = 'INSERT_COMPANY',
  EDIT_COMPANY = 'EDIT_COMPANY',
  REMOVE_COMPANY = 'REMOVE_COMPANY',
  VIEW_COMPANY = 'VIEW_COMPANY',
  VIEW_SCHEDULE_RECORDING = 'VIEW_SCHEDULE_RECORDING',
  INSERT_SCHEDULE_RECORDING = 'INSERT_SCHEDULE_RECORDING',
  EDIT_SCHEDULE_RECORDING = 'EDIT_SCHEDULE_RECORDING',
  REMOVE_SCHEDULE_RECORDING = 'REMOVE_SCHEDULE_RECORDING',
  VIEW_HIKVISION_SETTINGS = 'VIEW_HIKVISION_SETTINGS',
  EDIT_HIKVISION_SETTINGS = 'EDIT_HIKVISION_SETTINGS',
}

export interface MasterCompany {
  id: number;
  name: string;
  status: boolean;
  invoiceMargin: number | null | undefined;
  enableLicense: boolean;
}
export interface UserInitialValues {
  id?: number;
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  permission: PermissionGroup;
  status: boolean;
  companies: number[];
  enablePermissionCompany: boolean;
}
export interface User {
  id: number;
  name: string;
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  permission: PermissionGroup;
  status: boolean;
  masterCompany: MasterCompany;
  companies: CompanySimplifyListItem[];
  enablePermissionCompany: boolean;
}
