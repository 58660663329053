import { useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { atom, atomFamily } from 'recoil';

import {
  TestedUrlsKey,
  BatchTestResponse,
  Channel,
  ChannelInitialValues,
} from 'types/channels.types';

export const batchRTSPChannelsAtom = atom<Channel[]>({
  key: 'batchChannelFormAtom',
  default: [],
});

export const testedUrlsResultsAtom = atomFamily<BatchTestResponse, TestedUrlsKey>({
  key: 'testedUrlsResultsAtom',
  default: {},
});

export const useBatchLicensesValidation = () => {
  const formik = useFormikContext<ChannelInitialValues>();
  const { t } = useTranslation();
  const [hasError, setHasError] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState(
    formik.touched.channelGroup ? formik.errors.channelGroup : ''
  );

  const validateQtyChannels = React.useCallback(() => {
    const { qtyChannels, channelGroup } = formik.values;

    if (qtyChannels && channelGroup?.license) {
      const { maxAllowedChannels, enable } = channelGroup.license;
      const { consumedChannels } = channelGroup;

      const availableLicenses = maxAllowedChannels - Number(consumedChannels);

      if (availableLicenses === 0) {
        setHasError(true);
        setErrorMessage(t('license_models:no_channels_available'));
        return;
      }

      if (availableLicenses < qtyChannels) {
        setHasError(true);
        setErrorMessage(t('license_model:exceeded_license_to_qty_channel'));
        return;
      }

      if (!enable) {
        setHasError(true);
        setErrorMessage(t('license_model:license_not_enabled'));
        return;
      }
    }

    setHasError(false);
    setErrorMessage('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.qtyChannels, formik.values.channelGroup?.license, t]);

  React.useEffect(() => {
    const { channelGroup: channelGroupInitial } = formik.initialValues;
    const { channelGroup } = formik.values;
    if (channelGroupInitial?.license && channelGroup?.license)
      if (channelGroupInitial.license.id === channelGroup.license.id) return;

    validateQtyChannels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.qtyChannels, formik.values.channelGroup, t]);

  return {
    errorMessage,
    hasError,
  };
};
