import { atom } from 'recoil';
import { License } from 'types/license.types';

export const licenseSearchTextAtom = atom({
  key: 'licenseSearchTextAtom',
  default: '',
});

export const normalizeLicenses = (licenses: License[]) =>
  licenses.sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''));
