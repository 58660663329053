/* eslint-disable no-nested-ternary */
import React, { useMemo } from 'react';
import { ChannelDetails, ChannelVideo } from 'components/Channel';
import { Link, useParams } from 'react-router-dom';
import { asyncComponent } from 'HOCs';
import { useFetch } from 'hooks';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import NotFound from 'components/NotFound';
import { useTranslation } from 'react-i18next';
import ApiBoundary from 'components/ApiBoundary';
import { Customers } from 'components/core/Icons';
import routes from 'helpers/routes';
import { normalizeChannel } from 'hooks/channels/useChannelsApi';
import { Channel } from 'types/channels.types';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import { BIG, EXTRA_BIG, MEDIUM } from 'components/MosaicVideo/responsive.service';
import { useMeasure } from 'react-use';
import { metricsStartLoadingAtom } from 'atoms/mosaics';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useHavePermission } from 'hooks/useHavePermission';
import { PermissionRole } from 'types/user.types';
import { onlineChannelAtom } from 'atoms/channels';

const useStyles = makeStyles((theme) => ({
  link: {
    cursor: 'pointer',
    textDecoration: 'none',
    outline: 'none',
  },
  avatar: {
    color: theme.palette.text.primary,
    cursor: 'pointer',
    marginRight: theme.spacing(3),
    marginTop: theme.spacing(2),
  },
  headerClientName: {
    fontSize: '1.375rem',
  },
}));

function ChannelViewPage() {
  const { t } = useTranslation('channels');
  const { customerId, channelId } = useParams<{ customerId: string; channelId: string }>();
  const { data: channelUnformated } = useFetch(`/v1/clients/${customerId}/channels/${channelId}`, {
    normalizeData: normalizeChannel,
    refreshInterval: 15000,
  });

  const channel = useMemo(
    () =>
      channelUnformated && {
        ...channelUnformated,
        clientCompanyName: channelUnformated.client.companyName,
        clientTradeName: channelUnformated.client.tradeName,
        clientId: channelUnformated.client.id,
        clientStatus: channelUnformated.client.status,
      },
    [channelUnformated]
  );

  if (!channel) {
    return (
      <NotFound
        title={t('non_exixstent_channel')}
        reason={t('channel_does_not_exist_or_may_have_been_deleted')}
        shouldDo={t('register_a_new_channel_or_select_another_channel_to_be_displayed')}
      />
    );
  }

  return <ChannelView {...{ channel }} />;
}

function ChannelView({
  channel,
}: {
  channel: Channel & {
    clientCompanyName: string;
    clientTradeName: string;
    clientId: number;
    clientStatus: boolean;
  };
}) {
  const classes = useStyles();
  const [measureRef, measures] = useMeasure<HTMLDivElement>();
  const { t } = useTranslation('channels');
  const hasPermission = useHavePermission(PermissionRole.VIEW_CLIENT);
  const setMetricsStartLoading = useSetRecoilState(metricsStartLoadingAtom);
  const onlineChannel = useRecoilValue(onlineChannelAtom(channel.id));

  React.useEffect(() => {
    setMetricsStartLoading(new Date());

    return () => {
      setMetricsStartLoading(null);
    };
  }, [setMetricsStartLoading]);

  return (
    <Box padding="16px" flex="1 1 auto" flexDirection="column" display="flex" overflow="hidden">
      <Box marginBottom="8px" display="flex">
        <Customers className={classes.avatar} />

        <Link
          aria-label="Customer"
          to={hasPermission ? routes.customer.view(channel.clientId) : '#'}
          className={classes.link}
        >
          <Typography className={classes.headerClientName} variant="h6">
            {channel.clientTradeName}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {channel.clientCompanyName}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {`ID ${String(channel.clientId).padStart(6, '0')}`}
          </Typography>
        </Link>
      </Box>
      <Box flex="1 1 auto" display="flex" overflow="hidden">
        {channel && channel.client.status ? (
          !channel.status ? (
            <Box
              display="flex"
              flex="1 1 auto"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              ref={measureRef}
            >
              <Stack
                alignItems="center"
                direction={BIG.lower(measures) ? 'row' : 'column'}
                spacing={BIG.lower(measures) ? 1 : 0}
                display="flex"
                flexDirection="column"
                mb={5}
              >
                <VisibilityOffIcon
                  sx={{
                    fontSize: 99,
                  }}
                  color="action"
                />
                <Typography
                  align="center"
                  variant={
                    EXTRA_BIG.lower(measures) ? (BIG.lower(measures) ? 'subtitle2' : 'h6') : 'h5'
                  }
                >
                  {t('mosaic:slot_inative')}
                </Typography>
              </Stack>
              <Box mb={1}>
                <Typography
                  variant={
                    EXTRA_BIG.lower(measures)
                      ? BIG.lower(measures)
                        ? MEDIUM.lower(measures)
                          ? 'caption'
                          : 'body2'
                        : 'subtitle2'
                      : 'subtitle1'
                  }
                  display={EXTRA_BIG.lower(measures) ? 'none' : 'block'}
                  align="center"
                >
                  {t('channels:inactive_view')}
                </Typography>
              </Box>
            </Box>
          ) : (
            <>
              <Box overflow="hidden" flex="1 1 60%">
                <ApiBoundary
                  fallbackLoading={
                    <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                      <CircularProgress />
                    </Box>
                  }
                >
                  <ChannelVideo {...{ channel, onlineChannel }}>
                    <ChannelVideo.BottomPanel sx={{ p: 2, gap: 2, display: 'flex', right: 'auto' }}>
                      <ChannelVideo.PlayPauseAction {...{ channel }} />
                      <ChannelVideo.ZoomOutAction {...{ channel }} />
                    </ChannelVideo.BottomPanel>
                  </ChannelVideo>
                </ApiBoundary>
              </Box>
            </>
          )
        ) : (
          <Box
            display="flex"
            flex="1 1 auto"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            ref={measureRef}
          >
            <Stack
              alignItems="center"
              direction={BIG.lower(measures) ? 'row' : 'column'}
              spacing={BIG.lower(measures) ? 1 : 0}
              display="flex"
              flexDirection="column"
              mb={5}
            >
              <PersonOffIcon
                sx={{
                  fontSize: 99,
                }}
                color="action"
              />
              <Typography
                align="center"
                variant={
                  EXTRA_BIG.lower(measures) ? (BIG.lower(measures) ? 'subtitle2' : 'h6') : 'h5'
                }
              >
                {t('customers:inactive')}
              </Typography>
            </Stack>
            <Box mb={1}>
              <Typography
                variant={
                  EXTRA_BIG.lower(measures)
                    ? BIG.lower(measures)
                      ? MEDIUM.lower(measures)
                        ? 'caption'
                        : 'body2'
                      : 'subtitle2'
                    : 'subtitle1'
                }
                display={EXTRA_BIG.lower(measures) ? 'none' : 'block'}
                align="center"
              >
                {t('customers:inactive_description')}
              </Typography>
            </Box>
          </Box>
        )}
        <ChannelDetails {...{ channel }} customerId={channel.clientId} />
      </Box>
    </Box>
  );
}

export default asyncComponent(ChannelViewPage);
