import React, { useEffect } from 'react';
import usernameReplacementSrc from 'assets/username-replacement.png';
import passwordReplacementSrc from 'assets/password-replacement.png';
import amountReplacementSrc from 'assets/amount-replacement.png';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Switch,
  TextField,
  Tooltip,
  CircularProgress,
  Grid,
  Paper,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { ConfirmModal } from 'components/core';
import SelectRecordingType from 'components/SelectRecordingType';
import {
  BatchTestResponse,
  ProtocolType,
  RecordingType,
  ChannelConnectionResponse,
  ChannelInitialValues,
  BatchChannelInitialValues,
  BatchChannels,
} from 'types/channels.types';
import { ActiveTransmissionCreditLimit } from 'components/ActiveTransmissionCreditLimit/ActiveTransmissionCreditLimit';
import { ActiveStorageLimit } from 'components/ActiveStorageLimit/ActiveStorageLimit';
import HelpIcon from '@mui/icons-material/Help';
import Close from '@mui/icons-material/Close';
import SchedulingRecordSelect from 'components/SchedulingRecordSelect';
import { useTestingChannel } from 'components/forms/hooks/useTestingChannels';
import TestingDeviceFeedback from 'components/TestingDeviceFeedback';
import { ResponseMessageTooltip } from 'components/ResponseMessageTooltip/ResponseMessageTooltip';
import { testedUrlsResultsAtom, useBatchLicensesValidation } from 'atoms/batchChannel';
import { unwantedChars } from 'hooks/useYupObject';
import PasswordField from 'components/PasswordField/PasswordField';
import {
  TestBatchDeviceIcon,
  TestBatchDeviceText,
} from 'components/TestingDeviceFeedback/TestingDeviceFeedback';
import { useRecoilState, useRecoilValue } from 'recoil';
import { IntelbrasChannel } from 'types/intelbras.types';
import { HikVisionChannel } from 'types/hikvision.types';
import { transformBatchChannelsInObject } from 'helpers/transformBatchChannelsInObject';
import { useDecision } from '@optimizely/react-sdk';
import FEATURE_FLAGS_KEYS from 'constants/featureFlagsKeys';
import SelectChannelGroupV2 from 'components/SelectChannelGroupV2';
import SelectChannelGroup from 'components/SelectChannelGroup';
import { loggedUserAtom } from 'atoms/users';
import { LicenseRecordingType } from 'types/license.types';

function StatusInput() {
  const { t } = useTranslation();
  const formik = useFormikContext<ChannelInitialValues>();
  const [error, setError] = React.useState(false);
  const [errorText, setErrorText] = React.useState('');

  useEffect(() => {
    if (!formik.values.status && formik.values.recordingType === RecordingType.SCHEDULE) {
      setError(true);
      setErrorText(t('_common:channel_disabled_cant_be_vinculated_in_agenda'));
      return;
    }

    setError(false);
    setErrorText('');
  }, [formik.values.status, formik.values.recordingType, t]);

  return (
    <FormControl
      sx={{
        marginBottom: '1.2rem',
      }}
    >
      <FormControlLabel
        control={
          <Switch
            color="primary"
            id="status"
            name="status"
            onChange={formik.handleChange}
            checked={formik.values.status}
            value={formik.values.status}
          />
        }
        label={t(formik.values.status ? 'channels:active' : 'channels:inactive')}
      />
      {error && (
        <FormHelperText
          sx={(theme) => ({
            color: theme.palette.error.main,
          })}
        >
          {errorText}
        </FormHelperText>
      )}
    </FormControl>
  );
}
function NameInput() {
  const { t } = useTranslation();
  const formik = useFormikContext<ChannelInitialValues>();

  return (
    <TextField
      fullWidth
      id="name"
      name="name"
      data-testid="nameInput"
      label={t('channels:name')}
      placeholder={t('channels:enter_channel_name')}
      helperText={
        (formik.touched.name && formik.errors.name) || t('forms:string_max', { max: 100 })
      }
      value={formik.values.name}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={formik.touched.name && Boolean(formik.errors.name)}
      sx={{
        marginBottom: '0.6rem',
      }}
    />
  );
}

function SerialNumberInput() {
  const formik = useFormikContext<IntelbrasChannel>();

  return (
    <TextField
      onBlur={formik.handleBlur}
      id="serialNumber"
      data-testid="serialNumber"
      name="serialNumber"
      label="Serial"
      fullWidth
      placeholder="Serial"
      helperText={formik.touched.serialNumber && formik.errors.serialNumber}
      value={formik.values.serialNumber.trim()}
      onChange={formik.handleChange}
      error={formik.touched.serialNumber && Boolean(formik.errors.serialNumber)}
    />
  );
}

function P2PChannelInput() {
  const { t } = useTranslation();
  const formik = useFormikContext<IntelbrasChannel>();

  return (
    <TextField
      onBlur={formik.handleBlur}
      sx={{
        marginBottom: '0.6rem',
      }}
      id="p2pChannel"
      name="p2pChannel"
      type="number"
      data-testid="p2pChannel"
      label={t('_common:channel')}
      fullWidth
      placeholder={t('_common:channel')}
      helperText={formik.touched.p2pChannel && formik.errors.p2pChannel}
      value={formik.values.p2pChannel}
      onChange={formik.handleChange}
      error={formik.touched.p2pChannel && Boolean(formik.errors.p2pChannel)}
    />
  );
}

function ChannelGroupSelect() {
  const { t } = useTranslation();
  const formik = useFormikContext<ChannelInitialValues>();
  const loggedUser = useRecoilValue(loggedUserAtom);
  const { hasError, errorMessage } = useBatchLicensesValidation();

  const inPreviewStage = window.location.pathname.includes('/preview');

  return loggedUser?.masterCompany?.enableLicense ? (
    <SelectChannelGroupV2
      name="channelGroup"
      placeholder={t('channels:select_group')}
      error={hasError || (formik.touched.channelGroup && Boolean(formik.errors.channelGroup))}
      helperText={
        // eslint-disable-next-line no-nested-ternary
        hasError
          ? errorMessage
          : // @ts-ignore
          // eslint-disable-next-line no-nested-ternary
          inPreviewStage
          ? t('channels:in_preview_stage')
          : formik.touched.channelGroup
          ? formik.errors.channelGroup
          : ''
      }
      // @ts-ignore
      selectedChannelGroup={formik.values.channelGroup || null}
      onChangeChannelGroup={formik.handleChange}
      handleBlur={formik.handleBlur}
      disabled={!!inPreviewStage}
    />
  ) : (
    <SelectChannelGroup
      name="channelGroup"
      placeholder={t('channels:select_group')}
      error={formik.touched.channelGroup && Boolean(formik.errors.channelGroup)}
      // @ts-ignore
      helperText={formik.touched.channelGroup ? formik.errors.channelGroup?.id : ''}
      // @ts-ignore
      selectedChannelGroup={formik.values.channelGroup || null}
      onChangeChannelGroup={formik.handleChange}
      handleBlur={formik.handleBlur}
    />
  );
}

function RecordingTypeSelect() {
  const { t } = useTranslation();
  const formik = useFormikContext<ChannelInitialValues>();
  const [error, setError] = React.useState(false);
  const [errorText, setErrorText] = React.useState('');
  const loggedUser = useRecoilValue(loggedUserAtom);

  useEffect(() => {
    if (!formik.values.status && formik.values.recordingType === RecordingType.SCHEDULE) {
      setError(true);
      setErrorText(t('_common:channel_disabled_cant_be_vinculated_in_agenda'));
      return;
    }

    setError(false);
    setErrorText('');
  }, [formik.values.status, formik.values.recordingType, t]);

  useEffect(() => {
    if (
      formik.initialValues.recordingType === RecordingType.SCHEDULE &&
      formik.initialValues.simpleScheduledRecording?.id
    ) {
      setErrorText(t('common:channel_vinculated'));
      return;
    }

    setErrorText('');
  }, [formik.initialValues.recordingType, formik.initialValues.simpleScheduledRecording?.id, t]);

  const licenseInChannelGroup = formik.values.channelGroup?.license;
  const helperTextLicenseEnabled = loggedUser?.masterCompany.enableLicense && licenseInChannelGroup;

  return (
    <SelectRecordingType
      sx={{
        marginLeft: '16px',
      }}
      name="recordingType"
      placeholder={t('channels:select_type')}
      error={error || (formik.touched.recordingType && Boolean(formik.errors.recordingType))}
      helperText={
        // eslint-disable-next-line no-nested-ternary
        formik.touched.recordingType
          ? formik.errors.recordingType
          : helperTextLicenseEnabled
          ? t('license_models:defined_by_license')
          : errorText
      }
      // @ts-ignore
      selectedRecordingType={
        loggedUser?.masterCompany?.enableLicense
          ? formik.values.channelGroup?.license?.licenseType?.recordingType ??
            formik.values.recordingType
          : formik.values.recordingType
      }
      onChangeRecordingType={formik.handleChange}
      fullWidth
      disabled={loggedUser?.masterCompany?.enableLicense && !!licenseInChannelGroup}
    />
  );
}

function RetentionTimeInput() {
  const { t } = useTranslation();
  const formik = useFormikContext<ChannelInitialValues>();
  const loggedUser = useRecoilValue(loggedUserAtom);
  const { recordingType } = formik.values;

  const hasLicenseEnabled = loggedUser?.masterCompany?.enableLicense;
  const hasLicenseInChannelGroup = !!formik.values.channelGroup?.license;

  const hasLicense = hasLicenseEnabled && hasLicenseInChannelGroup;

  const recordingTypeLicense = formik.values.channelGroup?.license?.licenseType?.recordingType;
  const retentionTimeLicense = formik.values.channelGroup?.license?.licenseType?.retentionTime;

  const hasLicenseEnabledFlux =
    recordingTypeLicense && recordingTypeLicense !== LicenseRecordingType.LIVE;
  const noLicenseEnabledFlux =
    ((recordingType as unknown) as LicenseRecordingType) !== LicenseRecordingType.LIVE;

  const flux = hasLicense ? hasLicenseEnabledFlux : noLicenseEnabledFlux;

  return flux ? (
    <TextField
      sx={{
        marginLeft: '16px',
      }}
      fullWidth
      id="retentionTime"
      type="number"
      name="retentionTime"
      label={t('channels:total_hours_image_retention')}
      helperText={
        (formik.touched.retentionTime && formik.errors.retentionTime) || hasLicense
          ? t('license_models:defined_by_license')
          : t('forms:number_helper_text_between_values', {
              min: 1,
              max: 9999,
            })
      }
      value={hasLicense ? retentionTimeLicense : formik.values.retentionTime}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      disabled={hasLicense}
      error={formik.touched.retentionTime && Boolean(formik.errors.retentionTime)}
    />
  ) : null;
}

function CreditLimitCheckBox() {
  const formik = useFormikContext<ChannelInitialValues>();
  return (
    <ActiveTransmissionCreditLimit
      touched={formik.touched.activeTransmissionCreditLimit}
      change={formik.handleChange}
      checked={formik.values.activeTransmissionCreditLimit}
      error={formik.errors.activeTransmissionCreditLimit}
    />
  );
}

function StorageLimitCheckBox() {
  const formik = useFormikContext<ChannelInitialValues>();
  return (
    <ActiveStorageLimit
      touched={formik.touched.activeStorageLimit}
      change={formik.handleChange}
      checked={formik.values.activeStorageLimit}
      error={formik.errors.activeStorageLimit}
    />
  );
}

function CreditLimitInput() {
  const { t } = useTranslation();
  const formik = useFormikContext<ChannelInitialValues>();

  return (
    <FormControl
      sx={{
        width: '100%',
      }}
    >
      <InputLabel
        htmlFor="transmissionCreditLimit"
        error={
          formik.touched.transmissionCreditLimit && Boolean(formik.errors.transmissionCreditLimit)
        }
      >
        <Box display="flex" alignItems="center" gap={1}>
          {t('channels:limit_of_use_of_credits')}
          <Tooltip title={t('channels:tooltip_about_limit_use_of_credits')}>
            <HelpIcon fontSize="small" />
          </Tooltip>
        </Box>
      </InputLabel>

      <OutlinedInput
        label={
          <Box display="flex" alignItems="center" m={0} justifyContent="center">
            {t('channels:limit_of_use_of_credits')}
            <Tooltip title={t('channels:tooltip_about_limit_use_of_credits')}>
              <HelpIcon fontSize="small" />
            </Tooltip>
          </Box>
        }
        fullWidth
        endAdornment={<InputAdornment position="end">R$</InputAdornment>}
        data-testid="transmissionCreditLimit"
        id="transmissionCreditLimit"
        name="transmissionCreditLimit"
        value={formik.values.transmissionCreditLimit}
        type="number"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={
          formik.touched.transmissionCreditLimit && Boolean(formik.errors.transmissionCreditLimit)
        }
      />
      <FormHelperText
        error={
          formik.touched.transmissionCreditLimit && Boolean(formik.errors.transmissionCreditLimit)
        }
      >
        {(formik.touched.transmissionCreditLimit && formik.errors.transmissionCreditLimit) ||
          t('channels:warning_about_global_limit')}
      </FormHelperText>
    </FormControl>
  );
}

function StorageLimitInput() {
  const { t } = useTranslation();
  const formik = useFormikContext<ChannelInitialValues>();

  return (
    <FormControl sx={{ width: '100%', marginLeft: '16px' }}>
      <InputLabel
        htmlFor="storageLimit"
        error={formik.touched.storageLimit && Boolean(formik.errors.storageLimit)}
      >
        <Box display="flex" alignItems="center" gap={2}>
          {t('channels:limit_of_use_of_storage')}
          <Tooltip title={t('channels:tooltip_about_limit_of_use_of_storage')}>
            <HelpIcon fontSize="small" color="inherit" />
          </Tooltip>
        </Box>
      </InputLabel>
      <OutlinedInput
        label={
          <Box display="flex" alignItems="center" gap={1}>
            {t('channels:limit_of_use_of_storage')}
            <Tooltip title={t('channels:tooltip_about_limit_of_use_of_storage')}>
              <HelpIcon fontSize="small" />
            </Tooltip>
          </Box>
        }
        data-testid="storageLimit"
        fullWidth
        endAdornment={<InputAdornment position="end">GB</InputAdornment>}
        id="storageLimit"
        name="storageLimit"
        value={formik.values.storageLimit}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.storageLimit && Boolean(formik.errors.storageLimit)}
        type="number"
      />
      <FormHelperText error={formik.touched.storageLimit && Boolean(formik.errors.storageLimit)}>
        {(formik.touched.storageLimit && formik.errors.storageLimit) ||
          t('channels:warning_about_global_limit')}
      </FormHelperText>
    </FormControl>
  );
}

function PrivateChannel() {
  const { t } = useTranslation();
  const formik = useFormikContext<ChannelInitialValues>();

  return (
    <FormControl
      sx={{
        marginBottom: '1.2rem',
      }}
      error={formik.touched.channelPrivate && Boolean(formik.errors.channelPrivate)}
    >
      <FormControlLabel
        control={
          <Checkbox
            id="channelPrivate"
            name="channelPrivate"
            checked={formik.values.channelPrivate}
            onChange={formik.handleChange}
          />
        }
        label={t('channels:show_this_channel_only_on_my_security')}
      />
      <FormHelperText>
        {formik.touched.channelPrivate && formik.errors.channelPrivate}
      </FormHelperText>
    </FormControl>
  );
}

function ExtraStreamCheckBox() {
  const { t } = useTranslation();
  const formik = useFormikContext<IntelbrasChannel | HikVisionChannel>(); // used in p2p channels...

  return (
    <FormControl
      sx={{
        marginBottom: '1.2rem',
      }}
      error={formik.touched.subtypeStream && Boolean(formik.errors.subtypeStream)}
    >
      <FormControlLabel
        control={
          <Checkbox
            id="subtypeStream"
            name="subtypeStream"
            checked={formik.values.subtypeStream}
            onChange={formik.handleChange}
          />
        }
        label={t('channels:secondary_stream')}
      />
      <FormHelperText>{formik.touched.subtypeStream && formik.errors.subtypeStream}</FormHelperText>
    </FormControl>
  );
}

function RecordingScheduleSelect() {
  const formik = useFormikContext<ChannelInitialValues>();
  const { t } = useTranslation();
  const [helperText, setHelperText] = React.useState('');

  const hasError = !formik.values.status;

  useEffect(() => {
    if (!formik.values.status && formik.values.recordingType === RecordingType.SCHEDULE) {
      formik.setFieldError(
        'simpleScheduledRecording',
        t('_common:channel_disabled_cant_be_vinculated_in_agenda')
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.status, t]);

  const isScheduleLinked = Boolean(
    formik.initialValues.recordingType === RecordingType.SCHEDULE &&
      formik.initialValues.simpleScheduledRecording?.id
  );

  useEffect(() => {
    if (isScheduleLinked) {
      setHelperText(t('_common:unlinked_schedule_from_channel'));
      return;
    }

    setHelperText('');
  }, [isScheduleLinked, t]);

  return formik.values.recordingType === RecordingType.SCHEDULE ? (
    <React.Suspense fallback={<SchedulingRecordSelect.Loading />}>
      <SchedulingRecordSelect
        selectedRecord={formik.values.simpleScheduledRecording || null}
        setSelectedRecord={formik.handleChange}
        error={hasError}
        helperText={
          hasError ? t('_common:channel_disabled_cant_be_vinculated_in_agenda') : helperText
        }
      />
    </React.Suspense>
  ) : null;
}

function RTMPUrlInput() {
  const formik = useFormikContext<ChannelInitialValues>();
  const { t } = useTranslation();

  return (
    <TextField
      sx={{
        marginBottom: '0.6rem',
      }}
      fullWidth
      id="url"
      name="url"
      label="RTMP URL"
      disabled
      placeholder={t('channels:enter_RTMP_URL_address_camera')}
      helperText={
        (formik.touched.url && (formik.errors.url || formik.errors.url)) ||
        t('forms:string_max', { max: 500 })
      }
      value={formik.values.url.trim()}
      onBlur={formik.handleBlur}
      onChange={formik.handleChange}
      error={formik.touched.url && Boolean(formik.errors.url || formik.errors.url)}
    />
  );
}
interface TestChannelProps {
  channelType: 'live' | 'record';
}
function TestChannelButton({ channelType }: TestChannelProps) {
  const [response, setResponse] = React.useState<ChannelConnectionResponse>();
  const [testing, setTesting] = React.useState(false);
  const { testingSingleChannel } = useTestingChannel();
  const { t } = useTranslation('channels');
  const formik = useFormikContext<ChannelInitialValues>();

  const handleClick = React.useCallback(async () => {
    setResponse(undefined);
    setTesting(true);

    const urlChannel = {
      live: formik.values.url,
      record: formik.values.urlOnlyRecord,
    };

    const data = await testingSingleChannel({ url: urlChannel[channelType] });
    data && setResponse(data);
    setTesting(false);
  }, [formik.values.url, formik.values.urlOnlyRecord, testingSingleChannel, channelType]);

  React.useEffect(
    function setAsUndefinedWhenURLChanges() {
      setResponse(undefined);
    },
    [formik.values.url]
  );

  const urlRecordToFormatHasError = !!(
    formik.touched.urlOnlyRecordToFormat && Boolean(formik.errors.urlOnlyRecordToFormat)
  );
  const urlOnlyRecordHasError = Boolean(formik.errors.urlOnlyRecord);
  const hasError = urlRecordToFormatHasError || urlOnlyRecordHasError;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '1rem',
        marginBottom: '1.6rem',
      }}
    >
      <Button
        onClick={handleClick}
        variant="contained"
        aria-label={`url ${channelType} test`}
        disabled={testing || hasError}
      >
        {testing ? <CircularProgress size="1.6rem" /> : t('test')}
      </Button>
      {response && <TestingDeviceFeedback customizedCode={response.success} />}
      {response?.message && <ResponseMessageTooltip message={response.message} />}
    </Box>
  );
}

interface BatchButtonProps {
  atomKey: 'url' | 'urlOnlyRecord';
  ariaLabel: string;
}
function TestBatchChannelButton({ atomKey, ariaLabel }: BatchButtonProps) {
  const [response, setResponse] = useRecoilState<BatchTestResponse>(testedUrlsResultsAtom(atomKey));
  const [testing, setTesting] = React.useState(false);
  const { testingBatchChannels } = useTestingChannel();
  const [messageResponse, setMessageResponse] = React.useState<string | undefined>();
  const { t } = useTranslation('channels');
  const formik = useFormikContext<BatchChannelInitialValues>();

  const channels = React.useMemo<BatchChannels[]>(
    () =>
      Array.from({ length: formik.values.qtyChannels }).map((_, index) => ({
        url:
          atomKey === 'urlOnlyRecord'
            ? formik.values.urlOnlyRecord.replace('[amount]', String(index + 1))
            : formik.values.url.replace('[amount]', String(index + 1)),
        name: `${formik.values.name} ${index + 1}`,
      })),
    [
      atomKey,
      formik.values.name,
      formik.values.qtyChannels,
      formik.values.url,
      formik.values.urlOnlyRecord,
    ]
  );

  const handleClick = React.useCallback(async () => {
    setResponse({});
    setTesting(true);
    const data = await testingBatchChannels({ urls: channels });
    data && setResponse(transformBatchChannelsInObject(data));
    setTesting(false);
  }, [channels, setResponse, testingBatchChannels]);

  React.useEffect(() => {
    if (response.message) setMessageResponse(response.message);
  }, [response.message]);

  React.useEffect(() => setResponse({}), [
    setResponse,
    formik.values.url,
    formik.values.urlOnlyRecord,
    formik.values.qtyChannels,
  ]);

  const existTests =
    Object.keys(response).length > 0 && (formik.values.url || formik.values.urlOnlyRecord);

  const urlToFormatValidation = !!(
    formik.touched.urlToFormat && Boolean(formik.errors.urlToFormat)
  );
  const urlValidation = atomKey === 'url' && Boolean(formik.errors.url);

  const recordUrlHasError = atomKey === 'urlOnlyRecord' && Boolean(formik.errors.urlOnlyRecord);
  const isDisabled =
    urlToFormatValidation ||
    urlValidation ||
    formik.values.qtyChannels < 2 ||
    formik.values.qtyChannels > 999 ||
    !formik.values.name ||
    recordUrlHasError;

  return (
    <Box sx={{ marginTop: 2 }}>
      <Button
        onClick={handleClick}
        variant="contained"
        aria-label={ariaLabel}
        disabled={isDisabled || testing}
      >
        {testing ? <CircularProgress size="1.6rem" /> : t('channels:test')}
      </Button>

      {existTests
        ? channels.map((channel, index) => (
            <Grid
              key={channel.name}
              component={Paper}
              container
              spacing={1}
              alignItems="center"
              sx={{
                backgroundColor: 'background.default',
                padding: 1,
                my: 1,
              }}
            >
              <Grid item xs={1}>
                <Box gap={1} pr={1} display="flex">
                  <TestBatchDeviceIcon customizedCode={response[channel.url]} />
                  {messageResponse && response[channel.url] && (
                    <ResponseMessageTooltip message={messageResponse} />
                  )}
                </Box>
              </Grid>
              <Grid item xs={1.5}>
                <Typography variant="body2">Status</Typography>
                <Typography variant="body1">
                  {t(
                    `${TestBatchDeviceText({
                      customizedCode: response[channel.url],
                    })}`
                  )}
                </Typography>
              </Grid>
              <Grid item xs={3.5} title={channel.name}>
                <Typography variant="body2">{t('channels:channel_name')}</Typography>
                <Typography variant="body1" noWrap>
                  {channel.name}
                </Typography>
              </Grid>
              <Grid item xs={6} title={channel.url}>
                <Typography variant="body2">URL</Typography>
                <Typography variant="body1" noWrap>
                  {channel.url}
                </Typography>
              </Grid>
            </Grid>
          ))
        : null}
    </Box>
  );
}

function QtyChannelsInput({ protocolValue }: { protocolValue?: ProtocolType }) {
  const { t } = useTranslation('channels');
  const formik = useFormikContext<BatchChannelInitialValues>();

  return (
    <TextField
      type="number"
      id="qtyChannels"
      name="qtyChannels"
      label={t('channels:qty_channels')}
      fullWidth
      placeholder={t('channels:qnt_channels')}
      helperText={
        (formik.touched.qtyChannels && formik.errors.qtyChannels) ||
        (protocolValue === ProtocolType.RTSP && t('channels:validator_qtyChannels'))
      }
      value={formik.values.qtyChannels}
      onChange={(e) => {
        if (RegExp(unwantedChars).exec(e.target.value)) {
          return '';
        }

        formik.handleChange(e);
        return e;
      }}
      onBlur={formik.handleBlur}
      onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value.length > 3) {
          e.target.value = '';
        }
        return e;
      }}
      error={formik.touched.qtyChannels && Boolean(formik.errors.qtyChannels)}
    />
  );
}

function UsernameInput({ helperText }: { helperText?: string }) {
  const { t } = useTranslation();
  const formik = useFormikContext<ChannelInitialValues>();

  return (
    <TextField
      autoComplete="off"
      fullWidth
      id="user"
      name="user"
      label={t('channels:username')}
      helperText={
        (formik.touched.user && formik.errors.user) || helperText || ''
        // t('channels:in_the_url_field_replace_the_user_with_username')
      }
      value={formik.values.user.trim()}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={formik.touched.user && Boolean(formik.errors.user)}
    />
  );
}

function PasswordInput({ helperText }: { helperText?: string }) {
  const formik = useFormikContext<ChannelInitialValues>();
  const { t } = useTranslation();
  return (
    <PasswordField
      id="password"
      label={t('channels:password')}
      name="password"
      helperText={
        (formik.touched.password && formik.errors.password) || helperText || ''
        // t('channels:in_the_url_field_replace_the_password_with_password')
      }
      value={formik.values.password.trim()}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={formik.touched.password && Boolean(formik.errors.password)}
    />
  );
}
function PrivateKeyInput() {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [capsLock, setCapsLock] = React.useState(false);

  const formik = useFormikContext<HikVisionChannel>();

  return (
    <FormControl
      sx={{
        width: '100%',
      }}
    >
      <InputLabel
        htmlFor="privateKey"
        error={formik.touched.privateKey && Boolean(formik.errors.privateKey)}
      >
        <Box display="flex" alignItems="center" gap={1}>
          {t('channels:private_key')}
          <Tooltip title={t('channels:verification_code_helper')}>
            <HelpIcon fontSize="small" />
          </Tooltip>
        </Box>
      </InputLabel>

      <OutlinedInput
        label={
          <Box display="flex" alignItems="center" m={0} justifyContent="center">
            {t('channels:private_key')}
            <Tooltip title={t('channels:verification_code_helper')}>
              <HelpIcon fontSize="small" />
            </Tooltip>
          </Box>
        }
        value={formik.values.privateKey.trim()}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        placeholder={t('channels:private_key')}
        id="privateKey"
        name="privateKey"
        error={formik.touched.privateKey && Boolean(formik.errors.privateKey)}
        type={showPassword ? 'text' : 'password'}
        onKeyUp={(e) => {
          setCapsLock(e.getModifierState('CapsLock'));
        }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      />
      <FormHelperText error={formik.touched.privateKey && Boolean(formik.errors.privateKey)}>
        {(capsLock && t('channels:capslock_warning')) ||
          (formik.touched.privateKey && formik.errors.privateKey)}
      </FormHelperText>
    </FormControl>
  );
}

function SpecificUrlCheckbox() {
  const { t } = useTranslation();
  const formik = useFormikContext<ChannelInitialValues>();

  return (
    <FormControlLabel
      sx={{
        marginBottom: '1.2rem',
      }}
      control={
        <Checkbox
          id="specificUrl"
          name="specificUrl"
          checked={formik.values.specificUrl}
          onChange={formik.handleChange}
          value={formik.values.specificUrl}
        />
      }
      label={t('channels:use_a_specific_url_for_recording')}
    />
  );
}

function RTSPUrlInput() {
  const { t } = useTranslation();
  const formik = useFormikContext<BatchChannelInitialValues>();
  const [helpOpen, setHelpOpen] = React.useState(false);

  React.useEffect(() => {
    if (formik.values.user && formik.values.password) {
      formik.setFieldValue(
        'url',
        formik.values.urlToFormat
          .replace('[username]', encodeURIComponent(formik.values.user))
          .replace('[password]', encodeURIComponent(formik.values.password))
          .trim()
      );

      return;
    }
    formik.setFieldValue('url', formik.values.urlToFormat.trim());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formik.values.user,
    formik.values.password,
    formik.setFieldValue,
    formik.values.urlToFormat,
    formik.values.user,
    formik.values.qtyChannels,
  ]);

  return (
    <>
      <TextField
        fullWidth
        id="urlToFormat"
        name="urlToFormat"
        label="RTSP URL"
        placeholder={t('channels:enter_URL_address_camera')}
        helperText={
          (formik.touched.urlToFormat && (formik.errors.urlToFormat || formik.errors.url)) ||
          t('forms:rtsp_url_helper_text')
        }
        value={formik.values.urlToFormat.trim()}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={
          formik.touched.urlToFormat && Boolean(formik.errors.urlToFormat || formik.errors.url)
        }
        InputProps={{
          startAdornment: (
            <Tooltip title={t('channels:how_url_works')}>
              <IconButton onClick={() => setHelpOpen(true)} size="small" sx={{ mr: 1 }}>
                <HelpIcon fontSize="medium" color="inherit" />
              </IconButton>
            </Tooltip>
          ),
        }}
      />
      <HowToCustomURLBatchModal open={helpOpen} setOpen={setHelpOpen} />
    </>
  );
}

function HowToCustomURLBatchModal({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) {
  const { t } = useTranslation();
  return (
    <Dialog {...{ open }} onClose={() => setOpen(false)} maxWidth="md">
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {t('channels:how_url_works')}
        <IconButton onClick={() => setOpen(false)}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography sx={{ my: 2 }}>{t('channels:custom_url_using_following_tags')}</Typography>

        <Typography sx={{ mt: 2 }}>{t('channels:first_step_to_customize_the_url')}</Typography>

        <img
          src={usernameReplacementSrc}
          alt={t('channels:first_step_to_customize_the_url')}
          style={{ width: '100%', height: 'auto' }}
        />

        <Typography sx={{ mt: 2 }}>{t('channels:second_step_to_customize_the_url')}</Typography>

        <img
          src={passwordReplacementSrc}
          alt={t('channels:second_step_to_customize_the_url')}
          style={{ width: '100%', height: 'auto' }}
        />

        <Typography sx={{ mt: 2 }}>{t('channels:third_step_to_customize_the_url')}</Typography>

        <img
          src={amountReplacementSrc}
          alt={t('channels:third_step_to_customize_the_url')}
          style={{ width: '100%', height: 'auto' }}
        />

        <Typography sx={{ mt: 2, maxWidth: '400px' }}>
          {t('channels:replacement_done_after_customize_the_url')}
        </Typography>
      </DialogContent>
    </Dialog>
  );
}

export function HowToCustomURLSingleModal({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) {
  const { t } = useTranslation();
  return (
    <Dialog {...{ open }} onClose={() => setOpen(false)} maxWidth="md">
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {t('channels:how_url_works')}
        <IconButton onClick={() => setOpen(false)}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography sx={{ my: 2 }}>{t('channels:custom_url_using_following_tags')}</Typography>
        <Typography sx={{ mt: 2 }}>{t('channels:first_step_to_customize_the_url')}</Typography>
        <img
          src={usernameReplacementSrc}
          alt={t('channels:first_step_to_customize_the_url')}
          style={{ width: '100%', height: 'auto' }}
        />
        <Typography sx={{ mt: 2 }}>{t('channels:second_step_to_customize_the_url')}</Typography>
        <img
          src={passwordReplacementSrc}
          alt={t('channels:second_step_to_customize_the_url')}
          style={{ width: '100%', height: 'auto' }}
        />
        <Typography sx={{ mt: 2, maxWidth: '400px' }}>
          {t('channels:replacement_done_after_customize_the_url_single')}
        </Typography>
      </DialogContent>
    </Dialog>
  );
}

function RTSPUrlToFormatInput() {
  const { t } = useTranslation();
  const formik = useFormikContext<BatchChannelInitialValues>();
  const [helpOpen, setHelpOpen] = React.useState(false);

  React.useEffect(() => {
    if (formik.values.user && formik.values.password) {
      formik.setFieldValue(
        'url',
        formik.values.urlToFormat
          .replace('[username]', encodeURIComponent(formik.values.user))
          .replace('[password]', encodeURIComponent(formik.values.password))
          .trim()
      );

      return;
    }
    formik.setFieldValue('url', formik.values.urlToFormat.trim());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formik.values.user,
    formik.values.password,
    formik.setFieldValue,
    formik.values.urlToFormat,
    formik.values.user,
    formik.values.qtyChannels,
  ]);

  return (
    <>
      <TextField
        fullWidth
        id="urlToFormat"
        name="urlToFormat"
        label={t('channels:rtsp_url_live')}
        placeholder={t('channels:enter_URL_address_camera')}
        helperText={
          (formik.touched.urlToFormat && (formik.errors.urlToFormat || formik.errors.url)) ||
          t('forms:rtsp_url_helper_text')
        }
        value={formik.values.urlToFormat.trim()}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={
          formik.touched.urlToFormat && Boolean(formik.errors.urlToFormat || formik.errors.url)
        }
        InputProps={{
          startAdornment: (
            <Tooltip title={t('channels:how_url_works')}>
              <IconButton onClick={() => setHelpOpen(true)} size="small" sx={{ mr: 1 }}>
                <HelpIcon fontSize="medium" color="inherit" />
              </IconButton>
            </Tooltip>
          ),
        }}
      />
      <HowToCustomURLBatchModal open={helpOpen} setOpen={setHelpOpen} />
    </>
  );
}

function RTSPUrlOnlyRecordToFormatInput() {
  const { t } = useTranslation();
  const formik = useFormikContext<BatchChannelInitialValues>();
  React.useEffect(() => {
    if (formik.values.user && formik.values.password) {
      formik.setFieldValue(
        'urlOnlyRecord',
        formik.values.urlOnlyRecordToFormat
          .replace('[username]', encodeURIComponent(formik.values.user))
          .replace('[password]', encodeURIComponent(formik.values.password))
          .trim()
      );

      return;
    }

    formik.setFieldValue('urlOnlyRecord', formik.values.urlOnlyRecordToFormat.trim());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formik.values.user,
    formik.values.password,
    formik.setFieldValue,
    formik.values.urlOnlyRecordToFormat,
    formik.values.user,
    formik.values.qtyChannels,
  ]);

  return (
    <TextField
      fullWidth
      id="urlOnlyRecordToFormat"
      name="urlOnlyRecordToFormat"
      label={t('channels:rtsp_recording_url')}
      placeholder={t('channels:enter_URL_address_camera')}
      helperText={
        (formik.touched.urlOnlyRecordToFormat &&
          (formik.errors.urlOnlyRecordToFormat || formik.errors.urlOnlyRecord)) ||
        t('forms:string_max', { max: 500 })
      }
      value={formik.values.urlOnlyRecordToFormat.trim()}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={
        formik.touched.urlOnlyRecordToFormat &&
        Boolean(formik.errors.urlOnlyRecordToFormat || formik.errors.urlOnlyRecord)
      }
    />
  );
}

interface SaveButtonProps {
  disabled: boolean;
  isSubmitting: boolean;
  onClick: () => void;
  label?: string;
  titleModal?: string;
  descriptionModal?: string;
  openConfirmModal?: boolean;
}

function SaveButton({
  disabled,
  isSubmitting,
  onClick,
  label,
  titleModal,
  descriptionModal,
  openConfirmModal,
}: SaveButtonProps) {
  const { t } = useTranslation();
  const [confirmModalOpen, setConfirmModalOpen] = React.useState(false);
  const formik = useFormikContext<ChannelInitialValues>();

  const disabledByError =
    formik.values &&
    formik.values.status === false &&
    formik.values.recordingType === RecordingType.SCHEDULE;

  const { hasError } = useBatchLicensesValidation();

  return (
    <>
      <Button
        sx={{
          marginLeft: '1rem',
          padding: '0.375rem 2.75rem',
        }}
        variant="contained"
        color="primary"
        aria-label="save"
        disabled={disabledByError || disabled || hasError}
        onClick={openConfirmModal ? () => setConfirmModalOpen(true) : onClick}
      >
        {!confirmModalOpen && isSubmitting ? (
          <CircularProgress size="1.6rem" color="inherit" />
        ) : (
          label || t('crud_actions:save')
        )}
      </Button>
      <ConfirmModal
        open={confirmModalOpen}
        setOpen={setConfirmModalOpen}
        doConfirm={onClick}
        disabled={isSubmitting}
        confirmActionColor="primary"
        confirmActionText={
          isSubmitting ? <CircularProgress size="1.6rem" color="inherit" /> : undefined
        }
        loading={isSubmitting}
        title={titleModal || t('_common:wanna_update_channel')}
        variant="contained"
        description={descriptionModal || t('_common:confirm_update_channel_description')}
      />
    </>
  );
}

function EnablePreAlarmInput() {
  const { t } = useTranslation();
  const formik = useFormikContext<ChannelInitialValues>();
  // const [preAlarmFlag] = useDecision(FEATURE_FLAGS_KEYS.PRE_ALARM_RECORDING_TYPE);

  // if (!preAlarmFlag.enabled) return null;

  return formik.values.recordingType === RecordingType.EVENT ||
    formik.values.recordingType === RecordingType.SCHEDULE ? (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <FormControlLabel
        control={
          <Checkbox
            id="enablePreAlarm"
            name="enablePreAlarm"
            checked={formik.values.enablePreAlarm}
            onChange={formik.handleChange}
            value={formik.values.enablePreAlarm}
          />
        }
        label={t('channels:enable_pre_alarm')}
      />
      <Tooltip title={t('channels:about_pre_alarm')}>
        <HelpIcon fontSize="small" color="action" />
      </Tooltip>
    </Box>
  ) : null;
}

function PortInput() {
  const { t } = useTranslation();
  const formik = useFormikContext<ChannelInitialValues>();
  const [featureInputPortFlag] = useDecision(FEATURE_FLAGS_KEYS.FEATURE_INTELBRAS_INPUT_PORT);

  if (!featureInputPortFlag.enabled) return null;

  return (
    <FormControl
      sx={{
        width: '100%',
      }}
    >
      <InputLabel htmlFor="port" error={formik.touched.port && Boolean(formik.errors.port)}>
        <Box display="flex" alignItems="center" gap={1}>
          <Typography
            component="label"
            sx={{
              color: formik.touched.port && formik.errors.port ? '#F03A48' : 'inherit',
            }}
          >
            {t('channels:port')}
          </Typography>
          <Tooltip title={t('channels:port_tooltip')}>
            <HelpIcon
              fontSize="small"
              sx={{
                color: formik.touched.port && formik.errors.port ? '#F03A48' : 'inherit',
              }}
            />
          </Tooltip>
        </Box>
      </InputLabel>

      <OutlinedInput
        label={
          <Box display="flex" alignItems="center" m={0} justifyContent="center">
            <Typography
              sx={{
                color: formik.errors.port ? 'red' : 'inherit',
              }}
            >
              {t('channels:port')}
            </Typography>
            <Tooltip title={t('channels:port_tooltip')}>
              <HelpIcon fontSize="small" />
            </Tooltip>
          </Box>
        }
        fullWidth
        id="port"
        name="port"
        value={formik.values.port}
        type="number"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.port && Boolean(formik.errors.port)}
      />
      <FormHelperText error={formik.touched.port && Boolean(formik.errors.port)}>
        {formik.touched.port && formik.errors.port}
      </FormHelperText>
    </FormControl>
  );
}
const ChannelForm = {
  StatusInput,
  NameInput,
  ChannelGroupSelect,
  RecordingTypeSelect,
  RetentionTimeInput,
  CreditLimitCheckBox,
  StorageLimitCheckBox,
  CreditLimitInput,
  SerialNumberInput,
  P2PChannelInput,
  StorageLimitInput,
  PrivateChannel,
  RecordingScheduleSelect,
  RTMPUrlInput,
  TestChannelButton,
  TestBatchChannelButton,
  QtyChannelsInput,
  UsernameInput,
  PasswordInput,
  RTSPUrlInput,
  SpecificUrlCheckbox,
  RTSPUrlToFormatInput,
  RTSPUrlOnlyRecordToFormatInput,
  ExtraStreamCheckBox,
  PrivateKeyInput,
  SaveButton,
  EnablePreAlarmInput,
  PortInput,
};

export default ChannelForm;
