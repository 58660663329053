import { ChannelGroup } from './channelGroup.types';
import { SimpleScheduledRecording } from './recordingSchedule.types';

export enum CustomizedResponseCodeEnum {
  DEFAULT = 0,
  TRYING_OPEN_CONNECTION = 101,
  SUCCESS = 200,
  SIZE_PROBLEM = 425,
  MAXIMUM_ATTEMPTS_REACHED = 426,
  SHUTDOWN = 424,
  FAILED_TO_OPEN_CONNECTION = 400,
  CONNECTION_ERROR = 500,
  CODEC_NOT_SUPPORTED = 427,
  DEVICE_IS_OFFLINE = 408,
  NO_CONTENT = 204,
}

export enum RecordingType {
  RECORD_24_7 = 'RECORD_24_7',
  EVENT = 'EVENT',
  SCHEDULE = 'SCHEDULE',
  LIVE = 'LIVE',
}

export enum PartnerType {
  HIKVISION = 'HIKVISION',
  INTELBRAS = 'INTELBRAS',
  NO_P2P = 'NO_P2P',
}

export enum ProtocolType {
  RTSP = 'RTSP',
  RTMP = 'RTMP',
}

export enum ChannelType {
  IP_CAMERA = 'IP_CAMERA',
  DVR = 'DVR',
  NVR = 'NVR',
}

export enum Brand {
  INTELBRAS = 'INTELBRAS',
}

export enum Model {
  CAM_18X_IP_LT = 'CAM_18X_IP_LT',
}

export type TestedUrlsKey = 'url' | 'urlOnlyRecord';

export interface ChannelConnectionResponse {
  message?: string;
  success: CustomizedResponseCodeEnum;
  url: string;
}
export type IReturnUrlWithStatusCode = {
  [url: string]: CustomizedResponseCodeEnum;
};

export type BatchTestResponse = IReturnUrlWithStatusCode & {
  message?: string;
};

export interface VideoLiveSource {
  status: CustomizedResponseCodeEnum;
  url: string;
  token: string;
  channelId: number;
}

export interface Channel {
  id: number;
  name: string;
  type: ChannelType;
  qtyChannels: number;
  brand: Brand;
  model: Model;
  url: string;
  urlOnlyRecord: string;
  urlToFormat: string;
  urlOnlyRecordToFormat: string;
  specificUrl: boolean;
  user?: string;
  password?: string;
  stream: number;
  recordingType: RecordingType;
  retentionTime: number;
  alertCommunicationFailure: boolean;
  channelGroup?: ChannelGroup;
  keepAliveTime: number;
  status: boolean;
  client: {
    companyName: string;
    tradeName: string;
    id: number;
    status: boolean;
  };
  recording: boolean;
  online: boolean;
  channelPrivate: boolean;
  activeTransmissionCreditLimit: boolean;
  transmissionCreditLimit: number;
  activeStorageLimit: boolean;
  storageLimit: number;
  simpleScheduledRecording?: SimpleScheduledRecording | null;
  connectionTypeEnum?: 'RTMP' | 'RTSP' | 'NO_P2P' | 'P2P';
  idMask: string;
  enablePreAlarm: boolean;
  serialNumber: string;
}

export const DEFAULT_CHANNEL: ChannelInitialValues = {
  status: true,
  name: '',
  user: '',
  password: '',
  url: '',
  urlToFormat: '',
  recordingType: RecordingType.LIVE,
  retentionTime: 0,
  urlOnlyRecord: '',
  urlOnlyRecordToFormat: '',
  specificUrl: false,
  channelPrivate: false,
  activeTransmissionCreditLimit: false,
  transmissionCreditLimit: 0,
  activeStorageLimit: false,
  storageLimit: 0,
  enablePreAlarm: false,
  qtyChannels: 1,
};

export interface ChannelInitialValues {
  qtyChannels?: number;
  status: boolean;
  name: string;
  user: string;
  password: string;
  url: string;
  urlToFormat: string;
  recordingType: RecordingType;
  retentionTime: number;
  urlOnlyRecord: string;
  urlOnlyRecordToFormat: string;
  specificUrl: boolean;
  connectionTypeEnum?: ProtocolType;
  channelGroup?: ChannelGroup;
  id?: number;
  channelPrivate: boolean;
  activeTransmissionCreditLimit: boolean;
  transmissionCreditLimit: number;
  activeStorageLimit: boolean;
  storageLimit: number;
  simpleScheduledRecording?: SimpleScheduledRecording | null;
  enablePreAlarm: boolean;
  port?: number;
}

export interface BatchChannelInitialValues extends ChannelInitialValues {
  qtyChannels: number;
}

export interface BatchChannels {
  name: string;
  url: string;
  message?: string;
}

export const DEFAULT_BATCH_CHANNEL: BatchChannelInitialValues = {
  status: true,
  qtyChannels: 2,
  name: '',
  user: '',
  password: '',
  url: '',
  urlToFormat: '',
  recordingType: RecordingType.LIVE,
  retentionTime: 0,
  urlOnlyRecord: '',
  urlOnlyRecordToFormat: '',
  specificUrl: false,
  channelPrivate: false,
  activeTransmissionCreditLimit: false,
  transmissionCreditLimit: 0,
  activeStorageLimit: false,
  storageLimit: 0,
  enablePreAlarm: false,

  // type: '',
  // qtyChannels: '',
  // brand: '',
  // model: '',
  // stream: '',
  // keepAliveTime: '',
};
