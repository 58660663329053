import React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { PermissionRole } from 'types/user.types';
import Permissioned from 'components/Permissioned';
import { Container } from './EmptyState.styles';

interface Props {
  title: string;
  description: string | string[];
  captionButton: string;
  roleAdd: PermissionRole;
}

function EmptyState(
  props: React.PropsWithChildren<{ title: string; description: string | string[] }>
): React.ReactElement | null;
function EmptyState(
  props: React.PropsWithChildren<Props & { route?: string }>
): React.ReactElement | null;
function EmptyState(
  props: React.PropsWithChildren<Props & { onAddClick: React.MouseEventHandler }>
): React.ReactElement | null;
function EmptyState({
  title,
  description,
  captionButton,
  route,
  onAddClick,
  roleAdd,
}: React.PropsWithChildren<{
  title: string;
  description: string | string[];
  captionButton?: string;
  roleAdd?: PermissionRole;
  onAddClick?: React.MouseEventHandler;
  route?: string;
}>) {
  const buttonProps = {
    component: route ? Link : undefined,
    to: route,
    onClick: onAddClick,
  };
  return (
    <Container>
      <Typography variant="h6">{title}</Typography>
      {typeof description === 'string' ? (
        <Typography variant="body2" color="textSecondary">
          {description}
        </Typography>
      ) : (
        description.map((message, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Typography key={index} variant="body2" color="textSecondary">
            {message}
          </Typography>
        ))
      )}
      {roleAdd && (
        <Permissioned role={roleAdd}>
          <Button variant="contained" aria-label={captionButton} color="primary" {...buttonProps}>
            {captionButton}
          </Button>
        </Permissioned>
      )}
    </Container>
  );
}

export default EmptyState;
