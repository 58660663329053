/* eslint-disable arrow-body-style */
import React, { useState, useMemo } from 'react';
import { Event, EventFilterType } from 'types/event.types';
import { useFetch, useQuery } from 'hooks';
import { normalizeEvent } from 'hooks/event/event.types';
import { Namespace, TFunction, useTranslation } from 'react-i18next';
import { Box, Paper, TableRow, TableCell, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Column } from 'types/table.types';
import { timeFormat } from 'd3';
import routes from 'helpers/routes';
import exportAsPDF from 'services/exportAsPDF';
import Table from 'components/Table';
import { asyncComponent } from 'HOCs';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: '1 1 auto',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
}));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getTranslatedValue = (value: any, t: TFunction<Namespace<'_common' | 'events'>>) => {
  switch (value) {
    case 'Start Live Stream':
      return t('events:start_live_stream');
    case 'START':
      return t('_common:start_record');
    case 'STOP':
      return t('_common:stop_record');
    default:
      return value;
  }
};

function useColumns({ t }: { t: TFunction<Namespace<'_common' | 'events'>> }): Column<Event>[] {
  return useMemo(
    () => [
      {
        description: t('_common:date'),
        dataKey: 'date',
        renderValue: (value) => (value instanceof Date ? timeFormat('%d %B %Y %H:%M')(value) : ''),
      },
      {
        description: t('events:event'),
        dataKey: 'event',
        renderValue: (value) => getTranslatedValue(value, t),
      },
      {
        description: t('events:description'),
        dataKey: 'description',
        renderValue: (value) => getTranslatedValue(value, t),
      },
      {
        description: t('events:customer'),
        dataKey: 'clientTradeName',
        linkTo: (row) => routes.customer.view(row.clientId),
      },
      {
        description: t('events:channel'),
        dataKey: 'channelName',
        align: 'left',
        linkTo: (row) => routes.customer.channel.view(row.clientId, row.channelId),
      },
    ],
    [t]
  );
}

function EventTable() {
  const query = useQuery();
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const columns = useColumns({ t });
  const classes = useStyles();

  const { data: events } = useFetch<Event[]>(`/v1/events`, {
    normalizeData: (data) => data.map(normalizeEvent),
    conditionFn: () => Boolean(query.get('startDate') && query.get('endDate')),
    params: {
      startDate: query.get('startDate'),
      endDate: query.get('endDate'),
      clientIds: query.get('customers'),
      channelIds: query.get('channels'),
      channelGroupIds: query.get('channelGroups'),
      clientGroupIds: query.get('customerGroups'),
      recordingFilter: query.get('recordingTypes'),
      eventFilter:
        query.get('eventTypes') &&
        (query.get('eventTypes') as string)
          .split(',')
          .map((eventType) => EventFilterType[Number(eventType)])
          .join(','),
    },
  });

  const handleExportAsPDF = () => {
    events && exportAsPDF({ rows: events, t, title: t('events:channel_events'), columns });
  };

  if (events && events.length === 0) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" height="100%">
        <Typography color="textSecondary">{t('_common:no_results')}</Typography>
      </Box>
    );
  }

  return (
    <Paper className={classes.root}>
      <Table
        dataLength={events ? events.length : 0}
        tableName={t('events:events')}
        {...{ page, setPage, rowsPerPage, setRowsPerPage, handleExportAsPDF, columns }}
      >
        {events?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
          <TableRow key={row.id} aria-label={`linha do evento ${row.id}`}>
            {columns.map((column) => (
              <TableCell key={column.dataKey} aria-label={column.description}>
                <Table.Cell {...{ row, column }} />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </Table>
    </Paper>
  );
}

function Loading() {
  const { t } = useTranslation();
  const columns = useColumns({ t });
  return <Table.Loading {...{ columns }} />;
}

export default asyncComponent(EventTable, <Loading />);
