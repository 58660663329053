import React, { useRef, useEffect, useMemo, useState, useCallback } from 'react';
import VideoJsPlayer from 'video.js/dist/types/player';
import videojs from 'video.js';
import 'videojs-errors';
import './videoPlayer.css';
import { Socket, io } from 'socket.io-client';
import styled from 'styled-components';
import axios from 'axios';
import { Box, CircularProgress } from '@mui/material';
import { useUpdateEffect } from 'react-use';
import { Level } from 'hooks/useLogger';
import { loggedUserAtom } from 'atoms/users';
import { tokenAtom } from 'atoms/auth';
import { useRecoilState, useRecoilValue } from 'recoil';
import { VideoLiveSource } from 'types/channels.types';
import { useTranslation } from 'react-i18next';
import Tech from 'video.js/dist/types/tech/tech';
import { useDecision } from '@optimizely/react-sdk';
import FEATURE_FLAGS_KEYS from 'constants/featureFlagsKeys';
import { metricsStartLoadingAtom } from 'atoms/mosaics';
import { onlineChannelAtom } from 'atoms/channels';
import useZoom from './useZoom';
import {
  registerSourceHandler,
  getChannelOnlineById,
  getStatusPlaylistByChannelId,
} from './hlsPlugin';

registerSourceHandler(videojs);

const WidescreenContainer = styled.div<{ widescreen?: boolean }>`
  div {
    width: 100%;
    height: 100%;
  }

  ${({ widescreen }) =>
    widescreen &&
    `
    video {
      object-fit: fill;
    }
  `}
`;

interface Props {
  live?: boolean;
  fill?: boolean;
  widescreen?: boolean;
  noControlBar?: boolean;
  paused?: boolean;
  enableZoom?: boolean;
  channelId: number;
  onZoomChange?: (zoom: number) => void;
  zoom?: number;
  children?: (test: string) => React.ReactNode;
  setVideoPlayer?: (player: VideoJsPlayer | null) => void;
  authorization?: string;
  handleEnded?: () => void;
  onErrorPlaylist?: () => void;
  thumbnail?: string | null;
  id: string;
  doLog?: (message: string | Object, level: Level) => void;
  sources: { src: string }[];
  muted: boolean;
  autoplay: boolean;
  width: number;
  height: number;
  preload: string;
  channelName?: string;
}

function VideoPlayer({
  paused = false,
  enableZoom = false,
  onZoomChange,
  zoom = 1,
  children,
  setVideoPlayer,
  widescreen,
  authorization,
  handleEnded,
  onErrorPlaylist,
  live,
  thumbnail,
  channelId,
  channelName,
  id,
  doLog,
  ...props
}: Props) {
  const videoEl = useRef<HTMLVideoElement>(null);
  const playerRef = useRef<VideoJsPlayer>();
  const retry = useRef(0);
  const retryRestart = useRef(0);
  const retryTimeoutId = useRef<NodeJS.Timeout | 0>(0);
  const retryTimeoutIdRestart = useRef<NodeJS.Timeout | 0>(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const socket = useRef<Socket>();
  const loggedUser = useRecoilValue(loggedUserAtom);
  const token = useRecoilValue(tokenAtom);
  const [disabledProgressiveFlag] = useDecision(FEATURE_FLAGS_KEYS.DISABLED_PROGRESSIVE_HLS_CONFIG);
  const [enablePlayFromSecondFragment] = useDecision(
    FEATURE_FLAGS_KEYS.ENABLE_PLAY_FROM_SECOND_FRAGMENT
  );
  const startLoadingAt = useRecoilValue(metricsStartLoadingAtom);
  const firstPlayingRef = useRef(false);
  const [online, setOnline] = useRecoilState(onlineChannelAtom(channelId));

  useEffect(
    function setOnlineChannel() {
      const intervalId = setInterval(() => {
        setOnline(
          getChannelOnlineById(String(channelId)) === 200 &&
            getStatusPlaylistByChannelId(String(channelId)) === 200
        );
      }, 4000);

      return () => clearInterval(intervalId);
    },
    [channelId, setOnline]
  );

  const hlsConfig = useMemo(
    () =>
      disabledProgressiveFlag.enabled
        ? {
            progressive: false,
            backBufferLength: live ? 0 : 100,
            maxBufferLength: live ? 10 : 185,
            maxBufferSize: 60 * 1000 * 1000, // 60 MB
            // maxBufferHole: 1.5,
            startFragPrefetch: true,
            manifestLoadingMaxRetry: 10,
            manifestLoadingTimeOut: 20000,
            nudgeMaxRetry: 6,
            // initialLiveManifestSize: live ? 2 : 1,
            liveSyncDurationCount: enablePlayFromSecondFragment.enabled ? 0 : 8,
            manifestLoadingRetryDelay: 300,
            levelLoadingRetryDelay: 300,
            fragLoadingRetryDelay: 300,
            // @ts-ignore
            xhrSetup: (xhr, url) => {
              xhr.setRequestHeader('Cache-Control', 'no-store');

              authorization && xhr.setRequestHeader('Authorization', authorization);
            },
          }
        : {
            progressive: true,
            backBufferLength: live ? 0 : 100,
            maxBufferLength: live ? 10 : 185,
            maxBufferSize: 60 * 1000 * 1000, // 60 MB
            // maxBufferHole: 1.5,
            startFragPrefetch: true,
            manifestLoadingMaxRetry: 10,
            manifestLoadingTimeOut: 20000,
            nudgeMaxRetry: 6,
            // initialLiveManifestSize: live ? 2 : 1,
            liveSyncDurationCount: enablePlayFromSecondFragment.enabled ? 0 : 8,
            manifestLoadingRetryDelay: 300,
            levelLoadingRetryDelay: 300,
            fragLoadingRetryDelay: 300,
            fetchSetup: authorization
              ? // @ts-ignore
                (context, initParams) =>
                  new Request(context.url, {
                    ...initParams,
                    headers: new Headers({
                      authorization,
                    }),
                    cache: 'no-store',
                    priority: 'high',
                    referrerPolicy: 'unsafe-url',
                  })
              : undefined,
          },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [disabledProgressiveFlag.enabled, enablePlayFromSecondFragment.enabled]
  );

  useZoom({
    videoEl,
    zoom,
    enableZoom,
    onZoomChange,
  });
  const { t } = useTranslation();

  const restartPlayer = useCallback(
    async (socketChannelId: number) => {
      try {
        const { data } = await axios.get<VideoLiveSource>(`/v1/channels/${socketChannelId}/url`);

        if (props.sources?.some(({ src }: { src: string }) => !!src)) {
          if (retryRestart.current < 20) {
            retryTimeoutIdRestart.current = setTimeout(() => {
              if (!playerRef.current) return;
              retryRestart.current += 1;

              playerRef.current.reset();
              playerRef.current.src(data.url ?? props.sources[0].src);
            }, 2000);
          } else setError(t('video:error_restart_video'));
        }

        setLoading(true);
      } catch (err) {
        setError(t('video:error_restart_video'));
      }
    },
    [props.sources, t]
  );

  useEffect(
    function connectSocket() {
      if (!socket.current) {
        socket.current = io(`${process.env.REACT_APP_SOCKET_URL}`, {
          auth: {
            token,
          },
          transports: ['websocket'],
        });
      }

      return () => {
        socket.current && socket.current.disconnect();
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [token]
  );

  useEffect(
    function listenRestartPlayerEventBySocket() {
      if (socket?.current && loggedUser) {
        const RESTART_PLAYER_EVENT = `restart_player_${loggedUser.masterCompany.id}_${channelId}`;
        socket.current.off(RESTART_PLAYER_EVENT);
        socket.current.on(
          RESTART_PLAYER_EVENT,
          ({ channelId: channelSocketId }: { channelId: number; mastercompanyId: number }) =>
            restartPlayer(channelSocketId)
        );
      }
    },
    [socket, loggedUser, channelId, restartPlayer]
  );

  useEffect(
    function addAuthorization() {
      if (authorization) {
        // @ts-ignore
        // eslint-disable-next-line func-names
        videojs.Vhs.xhr.beforeRequest = function (options) {
          // eslint-disable-next-line no-param-reassign
          options.headers = {
            ...options.headers,
            authorization,
          };
          return JSON.parse(JSON.stringify(options));
        };
      }
    },
    [authorization]
  );

  useEffect(
    function createPlayer() {
      // @ts-ignore https://docs.videojs.com/tutorial-options.html#useractions
      playerRef.current = videojs(videoEl.current, {
        ...{ ...props, sources: undefined, inactivityTimeout: 0, errorDisplay: false },
        html5: {
          vhs: {
            overrideNative: true,
            handlePartialData: true,
            cacheEncryptionKeys: true,
          },
          hlsjsConfig: hlsConfig,
        },
        // @ts-ignore
        userActions: {
          doubleClick: false,
          hotkeys: {
            playPauseKey: () => {},
          },
        },
      });
      playerRef.current?.ready(function r() {
        if (props.sources && playerRef.current) {
          playerRef.current.src(props.sources[0]);
        }
      });
      if (props.noControlBar) {
        const controlBar = playerRef.current?.getChild('controlBar');
        if (controlBar) playerRef.current?.removeChild(controlBar);
      }

      playerRef.current?.on('error', function onError(e: Event) {
        e.stopImmediatePropagation();
        if (props.sources?.some(({ src }: { src: string }) => !!src)) {
          if (retry.current < 100) {
            retryTimeoutId.current = setTimeout(() => {
              if (!playerRef.current) return;
              retry.current += 1;
              const videoError = playerRef.current.error();
              // eslint-disable-next-line no-console
              console.error(`Retrying ${retry.current}`, videoError);
              playerRef.current.reset();
              // @ts-ignore
              playerRef.current.src(props.sources as Tech[]);
            }, 2000);
          } else setError(t('video:error_executing_video'));
        }
      });

      playerRef.current?.on('playing', function onPlaying() {
        setLoading(false);

        if (!firstPlayingRef.current && typeof startLoadingAt === 'object') {
          const now = new Date();

          // Calculate the difference in milliseconds
          const differenceInMilliseconds =
            now.getTime() - new Date(startLoadingAt as Date).getTime();

          // Convert the difference to seconds
          const differenceInSeconds = differenceInMilliseconds / 1000;

          if (differenceInSeconds > 0) {
            doLog?.(
              {
                startAt: (startLoadingAt as Date).toISOString(),
                duration: `${differenceInSeconds.toFixed(2)}s`, // Format the value with two decimal places
              },
              'info'
            );
          }

          firstPlayingRef.current = true;
        }
      });

      // https://github.com/videojs/video.js/issues/5435#issuecomment-471998039
      playerRef.current?.tech().on('retryplaylist', () => {
        onErrorPlaylist && onErrorPlaylist();
      });

      playerRef.current?.tech().on('errorNonFatal', () => {
        // @ts-ignore
        const videoError2 = playerRef.current?.tech().error();
        doLog?.({ ...videoError2 }, 'warn');
      });

      setVideoPlayer && setVideoPlayer(playerRef.current || null);

      return () => {
        setTimeout(() => {
          retryTimeoutId.current && clearTimeout(retryTimeoutId.current);
          retryTimeoutIdRestart.current && clearTimeout(retryTimeoutIdRestart.current);
          playerRef.current && playerRef.current.dispose();
        }, 1000);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useUpdateEffect(
    function changeSource() {
      if (playerRef.current && props.sources) {
        playerRef.current.src(props.sources[0]);
      }
    },
    [props.sources[0].src]
  );

  useEffect(
    function handlePlayPause() {
      if (!playerRef.current) {
        return;
      }
      paused ? playerRef.current.pause() : playerRef.current.play();
    },
    [paused]
  );

  useEffect(
    function handleMuted() {
      if (!playerRef.current) {
        return;
      }

      playerRef.current.muted(!!props.muted);
    },
    [props.muted]
  );

  useEffect(
    function recoveryStreaming() {
      const intervalId = setInterval(() => {
        if (getStatusPlaylistByChannelId(String(channelId)) === 204) {
          restartPlayer(channelId);
          setOnline(false);
        }
      }, 6000);

      return () => clearInterval(intervalId);
    },
    [channelId, loading, online, restartPlayer, setOnline]
  );
  const style = useMemo(() => {
    if (props.fill)
      return {
        width: '100%',
        height: '100%',
      };
    return {};
  }, [props.fill]);

  const className = useMemo(() => {
    const prefix = 'video-js';
    if (props.fill) return `${prefix} vjs-fill`;
    return prefix;
  }, [props.fill]);

  if (error) throw new Error(error);

  // used to DEBUG
  // console.log({
  //   id,
  //   source: props.sources,
  //   authorization,
  //   retry,
  //   playerRef,
  //   videoEl,
  //   retryRestart,
  //   error,
  //   loading,
  // });

  return (
    <WidescreenContainer {...{ style, widescreen }}>
      <div data-vjs-player>
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video id={id} ref={videoEl} className={className} />
        {loading && (
          <Box display="flex" alignItems="center" justifyContent="center">
            <CircularProgress />
          </Box>
        )}
      </div>
    </WidescreenContainer>
  );
}

export default VideoPlayer;
