import React from 'react';
import { useRecoilState } from 'recoil';
import { Redirect, useHistory } from 'react-router-dom';
import { Form } from 'components/core';
import { useQuery } from 'hooks';
import { useTranslation } from 'react-i18next';
import { Box, Button, CircularProgress, Divider } from '@mui/material';
import routes from 'helpers/routes';
import { hikvisionBatchFormAtom } from 'atoms/hikvision';
import { useHikVisionApi } from 'hooks/channels/manufactures/useHikvisionApi';
import HikvisionChannelPreview from './HikvisionChannelPreview';

interface Props {
  customerId: string;
}

export function HikvisionNextView({ customerId }: Props) {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [channels, setChannels] = useRecoilState(hikvisionBatchFormAtom);
  const { createBatchChannels } = useHikVisionApi(Number(customerId));
  const { t } = useTranslation();
  const history = useHistory();
  const query = useQuery();

  const handleSave = React.useCallback(async () => {
    try {
      setIsSubmitting(true);
      await createBatchChannels(channels);
      setIsSubmitting(false);
      history.push(routes.customer.root(Number(customerId)));
    } catch (error) {
      console.error(error);
      setIsSubmitting(false);
    }
  }, [createBatchChannels, channels, history, customerId]);

  if (channels.length === 0) {
    return <Redirect to={routes.customer.channel.create.selectBatchP2P(Number(customerId))} />;
  }

  return (
    <Box
      sx={{
        height: '100vh',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          flex: '1 1',
          overflow: 'auto',
          px: 4,
          py: 1.5,
        }}
      >
        {channels.map((channel, index) => (
          <HikvisionChannelPreview
            keyChannel={index + 1}
            channel={channel}
            customerId={customerId}
            key={`${channel.idMask}-${channel.name}`}
          />
        ))}
      </Box>
      <Divider />

      <Box
        sx={{
          height: '360px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flex: '0 0 auto',
            padding: '20px',
            justifyContent: 'flex-end',
            gap: 2,
          }}
        >
          <Form.CancelButton
            isSubmitting={isSubmitting}
            onClick={() => {
              history.push(
                query.get('returnRoute') ||
                  routes.customer.channel.create.formBatchHikvision(customerId)
              );
              setChannels([]);
            }}
          />
          <Button
            onClick={handleSave}
            variant="contained"
            aria-label={`register ${channels.length} channels`}
            color="primary"
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <CircularProgress size="1.6rem" />
            ) : (
              t('channels:register_channels', {
                number_channels: channels.length,
              })
            )}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
