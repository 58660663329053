import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  Typography,
} from '@mui/material';
import SelectFetchLoading from 'components/SelectFetchLoading';
import { useFetch } from 'hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ChannelGroup } from 'types/channelGroup.types';

function SelectChannelGroupV2({
  selectedChannelGroup,
  onChangeChannelGroup,
  error,
  helperText,
  placeholder,
  name,
  handleBlur,
  disabled,
}: {
  selectedChannelGroup: ChannelGroup | null;
  onChangeChannelGroup: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  error?: boolean;
  helperText?: string;
  placeholder?: string;
  name: string;
  handleBlur: (e: React.FocusEvent) => void;
  disabled?: boolean;
}) {
  const { data: channelGroups } = useFetch<ChannelGroup[]>('/v1/channelGroups');
  const { t } = useTranslation('channel_groups');

  const channelGroup = (id: number) => channelGroups?.find((group) => group.id === id);

  return (
    <FormControl variant="outlined" fullWidth error={error} disabled={disabled}>
      <InputLabel id="select-channel-group-label">{t('channel_group')}</InputLabel>
      <Select
        {...{ name, placeholder }}
        labelId="select-channel-group-label"
        id="select-channel-group"
        label={t('channel_group')}
        onChange={(e) => {
          const id = e.target.value as number;
          onChangeChannelGroup({
            ...e,
            target: {
              ...e.target,
              // @ts-ignore
              value: {
                id,
                label: channelGroup(id)?.name,
                ...channelGroup(id),
              },
            },
          });
        }}
        onBlur={handleBlur}
        value={selectedChannelGroup ? selectedChannelGroup.id : ''}
        renderValue={(selected) => {
          const selectedGroup = channelGroups?.find((group) => group.id === selected);
          return selectedGroup ? selectedGroup.name : '';
        }}
      >
        {channelGroups?.map(({ name: channelName, consumedChannels, id, ...rest }) => (
          <MenuItem
            key={id}
            value={id}
            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
          >
            <Typography variant="body1">{channelName}</Typography>

            {rest?.license && (
              <Typography variant="body2" sx={{ color: 'text.secondary', mb: 1 }}>
                {rest.license.name} •{' '}
                {t('license_models:available_licenses', {
                  count: rest.license.maxAllowedChannels - Number(consumedChannels),
                })}
              </Typography>
            )}
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

SelectChannelGroupV2.Loading = function SelectChannelGroupLoading() {
  const { t } = useTranslation('channel_groups');
  return <SelectFetchLoading label={t('channel_groups:channel_group')} />;
};

export default SelectChannelGroupV2;
