import React from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  FormHelperText,
  Typography,
  IconButton,
} from '@mui/material';
import SelectFetchLoading from 'components/SelectFetchLoading';
import { useTranslation } from 'react-i18next';
import { License } from 'types/license.types';
import { formatLicenseDescription } from 'helpers/formatLicenseDescription';
import { useFetch } from 'hooks';
import { normalizeLicenses } from 'atoms/license';
import { Clear } from '@mui/icons-material';

interface Props {
  selectedLicense?: License | null;
  setSelectedLicense: (license: License | null) => void;
  onChange: (value: React.ChangeEvent<HTMLSelectElement>) => void;
  hasError?: boolean;
  helperText?: string;
}
function SelectLicenseType({
  selectedLicense,
  setSelectedLicense,
  onChange,
  hasError,
  helperText,
}: Props) {
  const { t } = useTranslation();
  const { data: licenses } = useFetch<License[]>('/v1/licenses', {
    normalizeData: normalizeLicenses,
  });

  const handleChange = (event: SelectChangeEvent<License | null>) => {
    const value = Number(event.target.value);
    const license = licenses?.find((_license) => Number(_license.id) === value) || null;

    setSelectedLicense(license);

    onChange({
      ...event,
      target: {
        ...event.target,
        // @ts-ignore
        value: license,
      },
    });
  };

  const handleClear = () => {
    // @ts-ignore
    setSelectedLicense(null);
  };

  if (!licenses) {
    return <SelectLicenseType.Loading />;
  }

  if (!licenses.length) {
    return (
      <FormControl
        variant="outlined"
        fullWidth
        error={hasError}
        sx={{
          mb: '1.2rem',
          mt: '10px',
        }}
      >
        <InputLabel id="select-license-label-no-models">
          {t('license_models:license_type')}
        </InputLabel>
        <Select
          labelId="select-license-label-no-models"
          label={t('license_models:license_type')}
          value="0"
          disabled
        >
          <MenuItem value="0">{t('license_models:empty_licenses')}</MenuItem>
        </Select>
        <FormHelperText>{t('license_models:need_aditional_licenses')}</FormHelperText>
      </FormControl>
    );
  }

  return (
    <FormControl
      variant="outlined"
      fullWidth
      error={hasError}
      sx={{
        mb: '1.2rem',
        mt: '10px',
      }}
    >
      <InputLabel id="select-license-label">{t('license_models:license_type')}</InputLabel>
      <Select
        labelId="select-license-label"
        id="license"
        name="license"
        label={t('license_models:license_type')}
        // @ts-ignore
        value={selectedLicense?.id}
        onChange={handleChange}
        renderValue={(selected) => {
          const licenseFound = licenses?.find(
            (license) => license.id === Number(selectedLicense?.id)
          );

          return selected && licenseFound ? licenseFound.name : '';
        }}
        style={{ position: 'relative' }}
        endAdornment={
          selectedLicense?.id ? (
            <IconButton
              size="small"
              onClick={handleClear}
              style={{
                position: 'absolute',
                right: 0,
                top: '50%',
                transform: 'translateY(-50%)',
                marginRight: 30,
              }}
            >
              <Clear />
            </IconButton>
          ) : null
        }
      >
        <MenuItem aria-label="" value="" onClick={handleClear} />
        {licenses.map((license) => (
          <MenuItem
            key={license.id}
            id={String(license.id)}
            value={license.id}
            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
          >
            <Typography>{formatLicenseDescription(license.licenseType, t)}</Typography>
            {license && (
              <Typography variant="body2" sx={{ color: 'text.secondary', mb: 1 }}>
                {license.name ? `${license.name} • ` : ''}
                {t('license_models:available_licenses', {
                  count: license.maxAllowedChannels - Number(license.consumedChannels),
                })}
              </Typography>
            )}
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

SelectLicenseType.Loading = function SelectLoading() {
  const { t } = useTranslation();
  return <SelectFetchLoading label={t('license_models:license_type')} />;
};

export default SelectLicenseType;
