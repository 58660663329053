import { List, ListItem, ListItemText, SxProps, Theme, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PartnerType, RecordingType } from 'types/channels.types';
import { ChannelInMosaic } from 'types/mosaics.types';
import { OperationLogPlaylistItem, Value as TValue } from 'types/operationLogs.types';
import { ITimeRangeWithId } from 'types/recordingSchedule.types';

function NormalizeValue({ tag, value, sx }: { tag: string; value: TValue; sx?: SxProps<Theme> }) {
  const { t } = useTranslation();

  const text = React.useMemo(() => {
    if (tag === 'channels' && Array.isArray(value)) {
      return (value as ChannelInMosaic[])
        .map((channel) => `${channel.id} - ${channel.name}`)
        .join(', ');
    }

    if (tag === 'scheduledRecordingChannel' && Array.isArray(value)) {
      return (value as { name: string; id: number }[])
        .map((scheduleChannel) => `${scheduleChannel.name}`)
        .join(', ');
    }

    if (tag === 'license') {
      // @ts-ignore
      return value.name;
    }

    if (tag === 'scheduledRecordingTimeRanges' && Array.isArray(value)) {
      return ((value as unknown) as ITimeRangeWithId[])
        .map(
          (schedule) =>
            `${schedule.startTime} - ${schedule.endTime} - ${t(
              `channels:recording_type_${schedule.recordingType.toUpperCase()}`
            )} - ${t(`_common:week_day_${schedule.weekDay.toUpperCase()}`)}`
        )
        .join(', ');
    }
    if (value === PartnerType.NO_P2P) return t('channels:no_p2p');

    if (value === RecordingType.LIVE) return t('channels:recording_type_LIVE');

    if (value === RecordingType.RECORD_24_7) return t('channels:recording_type_RECORD_24_7');

    if (value === RecordingType.EVENT) return t('channels:recording_type_EVENT');

    if (value === RecordingType.SCHEDULE) return t('channels:recording_type_SCHEDULE');

    // if (value === RecordingType.PRE_ALARM) return t('channels:recording_type_PRE_ALARM');

    if (typeof value === 'boolean') {
      return value ? t('_common:active') : t('_common:inactive');
    }

    if (typeof value === 'object') return JSON.stringify(value);
    return value;
  }, [t, tag, value]);

  if (tag === 'items' && Array.isArray(value)) {
    return (
      <List>
        {(value as OperationLogPlaylistItem[])
          .sort((a, b) => a.order - b.order)
          .map((item) => (
            <ListItem key={item.id}>
              <ListItemText>{`${item.id} - ${item.name}`}</ListItemText>
              <ListItemText>{`${t('playlists:screen_time')}: ${t('_common:second', {
                count: item.rotation_time,
              })}`}</ListItemText>
            </ListItem>
          ))}
      </List>
    );
  }

  return (
    <Typography variant="body2" sx={sx}>
      {text}
    </Typography>
  );
}

export default NormalizeValue;
