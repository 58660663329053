import { OperationItems } from 'types/operationLogs.types';

const routes = {
  mosaic: {
    root: '/mosaics',
    view: (mosaicId: Param) => `/mosaics/${mosaicId}/view`,
    recorded: (mosaicId: Param, startDate: Param) =>
      startDate ? `/mosaics/${mosaicId}/recorded/${startDate}` : `/mosaics/${mosaicId}/recorded`,

    slotView: (mosaicId: Param, slotId: Param) => `/mosaics/${mosaicId}/slot/${slotId}/view`,
    createChannel: mosaicCreateChannel,
    createCustomer: mosaicCreateCustomer,
    fullscreen: (mosaicId: Param) => `/mosaics/${mosaicId}/fullscreen`,
  },
  customer: {
    root: customerRoot,
    edit: customerEdit,
    view: (id: Param) => `/customers/${id}/view`,
    create: customerCreate,
    channel: {
      edit: channelEdit,
      editHikvision: hikvisionEdit,
      editIntelbras: intelbrasEdit,
      editRTMP: channelRTMPEdit,
      view: (customerId: Param, id: Param) => `/customers/${customerId}/channels/${id}/view`,
      playback: (customerId: Param, id: Param) => `/customer/${customerId}/channels/${id}/playback`,
      create: {
        root: channelCreateRoot,
        // TODO: Remover rotas  (Juntas as de preview, tendo um componente root que define qual componente irá renderizar)
        selectP2P: panelSelectP2P,
        registerHikvision: singleHikvisionChannel,
        registerIntelbras: singleIntelbrasChannel,
        selectBatchP2P,
        formBatchIntelbras,
        formBatchHikvision,
        previewBatchIntelbras,
        previewBatchHikvision,
        channelCreateBatch,
        rtmpChannelsPreview,
        rtspChannelsPreview,
      },
    },
  },
  settings: {
    root: '/settings',
    licenses: {
      root: '/settings/licenses',
    },
    user: {
      root: '/settings/users',
      edit: (id: Param) => `/settings/users/${id}/edit`,
      create: '/settings/users/new',
    },
    usageSummary: {
      root: '/settings/usagesummary',
      details: '/settings/usagesummary/details',
    },
    channelGroup: '/settings/channelgroup',
    customerGroup: '/settings/customerGroup',
    operationLogs: {
      root: operationLogsRoot,
      details: (id: Param) => `/settings/operationLogs/${id}`,
    },
    channels: '/settings/channels',
    hikvision: '/settings/hikvision',
    recordingSchedule: {
      root: '/settings/recordingSchedules',
      create: '/settings/recordingSchedules/new',
      edit: (id: Param) => `/settings/recordingSchedules/${id}/edit`,
    },
    companies: {
      root: companiesRoot,
      create: '/settings/companies/new',
      edit: (id: Param) => `/settings/companies/${id}/edit`,
    },
    speedTest: {
      root: '/settings/speed-test',
    },
  },
  welcome: {
    root: '/welcome',
  },
  download: {
    root: '/download',
  },
  calculator: {
    root: '/calculator',
  },
  metrics: {
    root: () => '/metrics',
    storage: metricsTab('/metrics/storage'),
    recording: metricsTab('/metrics/recording'),
    live: metricsTab('/metrics/live'),
    reproduction: metricsTab('/metrics/reproduction'),
    streaming: () => '/metrics/streaming',
    details: metricsTab('/metrics/details'),
    consumption: consumptionTab('/metrics/consumption'),
  },
  channels: {
    monitoring: channelsMonitoring,
  },
  events: {
    root: eventRoot,
  },
  recordings: {
    root: recordings,
  },
  playlists: {
    root: () => '/playlists',
    play: (id: Param) => `/playlists/${id}/play`,
    fullscreen: (id: Param) => `/playlists/${id}/fullscreen`,
  },
};

export default routes;

export const mosaicViewContext = [
  '/mosaics/:mosaicId/view',
  mosaicCreateChannel(':mosaicId', ':slotId', ':customerId'),
  mosaicCreateCustomer(':mosaicId', ':slotId'),
];

type Param = string | number;

function channelEdit(customerId: Param, id: Param, returnRoute: string): string;
function channelEdit(customerId: Param, id: Param): string;
function channelEdit(customerId: Param, id: Param, returnRoute?: string): string {
  const route = `/customers/${customerId}/channels/${id}/edit`;
  return returnRoute ? `${route}?returnRoute=${returnRoute}` : route;
}

function channelRTMPEdit(customerId: Param, id: Param, returnRoute: string): string;
function channelRTMPEdit(customerId: Param, id: Param): string;
function channelRTMPEdit(customerId: Param, id: Param, returnRoute?: string): string {
  const route = `/customers/${customerId}/rtmp/channels/${id}/edit`;
  return returnRoute ? `${route}?returnRoute=${returnRoute}` : route;
}

function hikvisionEdit(customerId: Param, id: Param, returnRoute: string): string;
function hikvisionEdit(customerId: Param, id: Param): string;
function hikvisionEdit(customerId: Param, id: Param, returnRoute?: string): string {
  const route = `/customers/${customerId}/hikvision/channels/${id}/edit`;
  return returnRoute ? `${route}?returnRoute=${returnRoute}` : route;
}

function intelbrasEdit(customerId: Param, id: Param, returnRoute: string): string;
function intelbrasEdit(customerId: Param, id: Param): string;
function intelbrasEdit(customerId: Param, id: Param, returnRoute?: string): string {
  const route = `/customers/${customerId}/intelbras/channels/${id}/edit`;
  return returnRoute ? `${route}?returnRoute=${returnRoute}` : route;
}

function mosaicCreateChannel(mosaicId: string, slotId: string, customerId: string): string;
function mosaicCreateChannel(mosaicId: number, slotId: number, customerId: number): string;
function mosaicCreateChannel(mosaicId: Param, slotId: Param, customerId: Param): string {
  const route = `/mosaics/${mosaicId}/slot/${slotId}/customers/${customerId}/channels/new`;
  return typeof mosaicId === 'string' ? route : `${route}?returnRoute=/mosaics/${mosaicId}/view`;
}

function mosaicCreateCustomer(mosaicId: string, slotId: string): string;
function mosaicCreateCustomer(mosaicId: number, slotId: number): string;
function mosaicCreateCustomer(mosaicId: Param, slotId: Param): string {
  const route = `/mosaics/${mosaicId}/slot/${slotId}/customers/new`;
  return typeof mosaicId === 'string' ? route : `${route}?returnRoute=/mosaics/${mosaicId}/view`;
}

function channelCreateRoot(customerId: Param, returnRoute?: string) {
  const route = `/customers/${customerId}/channels/new`;
  return returnRoute ? `${route}?returnRoute=${returnRoute}` : route;
}

function selectBatchP2P(customerId: Param): string;
function selectBatchP2P(customerId: Param, returnRoute: string): string;
function selectBatchP2P(customerId: Param, returnRoute?: string) {
  const route = `/customers/${customerId}/channels/new/select-batch-p2p`;
  return returnRoute ? `${route}?returnRoute=${returnRoute}` : route;
}

function panelSelectP2P(customerId: Param): string;
function panelSelectP2P(customerId: Param, returnRoute: string): string;
function panelSelectP2P(customerId: Param, returnRoute?: string) {
  const route = `/customers/${customerId}/channels/new/p2p`;
  return returnRoute ? `${route}?returnRoute=${returnRoute}` : route;
}
function formBatchIntelbras(customerId: Param): string;
function formBatchIntelbras(customerId: Param, returnRoute: string): string;
function formBatchIntelbras(customerId: Param, returnRoute?: string) {
  const route = `/customers/${customerId}/channels/new/select-batch-p2p/intelbras`;
  return returnRoute ? `${route}?returnRoute=${returnRoute}` : route;
}
function formBatchHikvision(customerId: Param): string;
function formBatchHikvision(customerId: Param, returnRoute: string): string;
function formBatchHikvision(customerId: Param, returnRoute?: string) {
  const route = `/customers/${customerId}/channels/new/select-batch-p2p/hikvision`;
  return returnRoute ? `${route}?returnRoute=${returnRoute}` : route;
}

function previewBatchIntelbras(customerId: Param): string;
function previewBatchIntelbras(customerId: Param, returnRoute: string): string;
function previewBatchIntelbras(customerId: Param, returnRoute?: string) {
  const route = `/customers/${customerId}/channels/new/select-batch-p2p/intelbras/preview`;
  return returnRoute ? `${route}?returnRoute=${returnRoute}` : route;
}
function previewBatchHikvision(customerId: Param): string;
function previewBatchHikvision(customerId: Param, returnRoute: string): string;
function previewBatchHikvision(customerId: Param, returnRoute?: string) {
  const route = `/customers/${customerId}/channels/new/select-batch-p2p/hikvision/preview`;
  return returnRoute ? `${route}?returnRoute=${returnRoute}` : route;
}

function singleHikvisionChannel(customerId: Param): string;
function singleHikvisionChannel(customerId: Param, returnRoute: string): string;
function singleHikvisionChannel(customerId: Param, returnRoute?: string) {
  const route = `/customers/${customerId}/channels/new/p2p/hikvision`;
  return returnRoute ? `${route}?returnRoute=${returnRoute}` : route;
}

function singleIntelbrasChannel(customerId: Param): string;
function singleIntelbrasChannel(customerId: Param, returnRoute: string): string;
function singleIntelbrasChannel(customerId: Param, returnRoute?: string) {
  const route = `/customers/${customerId}/channels/new/p2p/intelbras`;
  return returnRoute ? `${route}?returnRoute=${returnRoute}` : route;
}

function channelCreateBatch(customerId: Param, returnRoute?: string) {
  const route = `/customers/${customerId}/channels/new/batch`;
  return returnRoute ? `${route}?returnRoute=${returnRoute}` : route;
}

function rtmpChannelsPreview(customerId: Param): string;
function rtmpChannelsPreview(customerId: Param, returnRoute: string): string;
function rtmpChannelsPreview(customerId: Param, returnRoute?: string) {
  const route = `/customers/${customerId}/channels/new/batch/protocol/rtmp/preview`;
  return returnRoute ? `${route}?returnRoute=${returnRoute}` : route;
}

function rtspChannelsPreview(customerId: Param): string;
function rtspChannelsPreview(customerId: Param, returnRoute: string): string;
function rtspChannelsPreview(customerId: Param, returnRoute?: string) {
  const route = `/customers/${customerId}/channels/new/batch/protocol/rtsp/preview`;
  return returnRoute ? `${route}?returnRoute=${returnRoute}` : route;
}

function customerEdit(customerId: Param): string;
function customerEdit(customerId: Param, returnRoute: string): string;
function customerEdit(customerId: Param, returnRoute?: string) {
  const route = `/customers/${customerId}/edit`;
  return returnRoute ? `${route}?returnRoute=${returnRoute}` : route;
}

function customerCreate(): string;
function customerCreate(returnRoute: string): string;
function customerCreate(returnRoute?: string) {
  const route = '/customers/new';
  return returnRoute ? `${route}?returnRoute=${returnRoute}` : route;
}

function customerRoot(): string;
function customerRoot(customerId: number): string;
function customerRoot(customerId?: number) {
  return customerId ? `/customers?selectedCustomer=${customerId}` : `/customers`;
}

function companiesRoot(): string;
function companiesRoot(id: number): string;
function companiesRoot(id?: number) {
  return id ? `/settings/companies?selectedCompany=${id}` : `/settings/companies`;
}

function eventRoot({
  startDate,
  endDate,
  selectedCustomers,
  selectedRecordingTypes,
  selectedCustomerGroup,
  selectedChannel,
  selectedChannelGroup,
  selectedEventTypes,
}: {
  startDate?: Date;
  endDate?: Date;
  selectedCustomers?: number[];
  selectedRecordingTypes?: string[];
  selectedCustomerGroup?: number[];
  selectedChannel?: number[];
  selectedChannelGroup?: number[];
  selectedEventTypes?: number[];
} = {}) {
  const urlSearchParams = new URLSearchParams();
  startDate && urlSearchParams.append('startDate', startDate.toISOString());
  endDate && urlSearchParams.append('endDate', endDate.toISOString());
  selectedCustomers?.length && urlSearchParams.append('customers', selectedCustomers.join(','));
  selectedEventTypes?.length && urlSearchParams.append('eventTypes', selectedEventTypes.join(','));
  selectedRecordingTypes &&
    selectedRecordingTypes.length > 0 &&
    urlSearchParams.append('recordingTypes', selectedRecordingTypes.join(','));
  selectedCustomerGroup?.length &&
    urlSearchParams.append('customerGroups', selectedCustomerGroup.join(','));
  selectedChannel?.length && urlSearchParams.append('channels', selectedChannel.join(','));
  selectedChannelGroup?.length &&
    urlSearchParams.append('channelGroups', selectedChannelGroup.join(','));
  const route = '/events';
  return urlSearchParams.toString() ? `${route}?${urlSearchParams.toString()}` : route;
}

function channelsMonitoring(
  {
    selectedChannelGroups,
    selectedCustomerGroups,
    selectedCustomers,
    selectedRecordingTypes,
    recording,
    online,
    channelName,
  }: {
    selectedChannelGroups?: number[];
    selectedCustomerGroups?: number[];
    selectedCustomers?: number[];
    selectedRecordingTypes?: string[];
    recording: boolean | null;
    online: boolean | null;
    channelName: string | null;
  } = { recording: null, channelName: null, online: null }
) {
  const urlSearchParams = new URLSearchParams();

  selectedChannelGroups?.length &&
    urlSearchParams.append('channelGroups', selectedChannelGroups.join(','));

  selectedCustomerGroups?.length &&
    urlSearchParams.append('customerGroups', selectedCustomerGroups.join(','));

  selectedCustomers?.length && urlSearchParams.append('customers', selectedCustomers.join(','));

  selectedRecordingTypes?.length &&
    urlSearchParams.append('recordingTypes', selectedRecordingTypes.join(','));

  recording !== null && urlSearchParams.append('recording', String(Number(recording)));

  online !== null && urlSearchParams.append('online', String(Number(online)));

  channelName && urlSearchParams.append('channelName', channelName);

  const route = '/channels/monitoring';
  return urlSearchParams.toString() ? `${route}?${urlSearchParams.toString()}` : route;
}

function recordings(
  {
    selectedCustomer,
    selectedChannels,
    startDate,
    endDate,
  }: {
    selectedCustomer: number | null;
    selectedChannels?: number[];
    startDate?: Date | null;
    endDate?: Date | null;
  } = { selectedCustomer: null }
) {
  const urlSearchParams = new URLSearchParams();
  selectedCustomer && urlSearchParams.append('customer', String(selectedCustomer));
  selectedChannels?.length && urlSearchParams.append('channels', selectedChannels.join(','));
  startDate && urlSearchParams.append('startDate', startDate.toISOString());
  endDate && urlSearchParams.append('endDate', endDate.toISOString());

  const route = '/recordings';
  return urlSearchParams.toString() ? `${route}?${urlSearchParams.toString()}` : route;
}

function metricsTab(route: string) {
  return ({ startDate, endDate }: { startDate?: Date; endDate?: Date } = {}) => {
    const urlSearchParams = new URLSearchParams();

    startDate && urlSearchParams.append('startDate', startDate.toISOString());
    endDate && urlSearchParams.append('endDate', endDate.toISOString());

    return urlSearchParams.toString() ? `${route}?${urlSearchParams.toString()}` : route;
  };
}

function consumptionTab(route: string) {
  return ({ month, year }: { month?: string; year?: string } = {}) => {
    const urlSearchParams = new URLSearchParams();

    month && urlSearchParams.append('month', month);
    year && urlSearchParams.append('year', year);

    return urlSearchParams.toString() ? `${route}?${urlSearchParams.toString()}` : route;
  };
}

function operationLogsRoot({
  startDate,
  endDate,
  selectedCustomer,
  selectedUser,
  selectedOperation,
  selectedItem,
}: {
  startDate?: Date | null;
  endDate?: Date | null;
  selectedCustomer?: number | null;
  selectedUser?: number | null;
  selectedOperation?: string | null;
  selectedItem?: OperationItems | null;
} = {}) {
  const urlSearchParams = new URLSearchParams();
  startDate && urlSearchParams.append('startDate', startDate.toISOString());
  endDate && urlSearchParams.append('endDate', endDate.toISOString());
  selectedCustomer && urlSearchParams.append('customer', String(selectedCustomer));
  selectedUser && urlSearchParams.append('user', String(selectedUser));
  selectedOperation && urlSearchParams.append('operation', selectedOperation);
  selectedItem && urlSearchParams.append('item', selectedItem);

  const route = '/settings/operationLogs';
  return urlSearchParams.toString() ? `${route}?${urlSearchParams.toString()}` : route;
}
