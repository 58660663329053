import React from 'react';
import EmptyState from 'components/EmptyState';
import { useTranslation } from 'react-i18next';

function EmptyLicenses({ route }: { route?: string }) {
  const { t } = useTranslation('license_models');

  return (
    <>
      <EmptyState
        title={t('license_models:there_are_no_licenses')}
        description={t('license_models:add_new_license_to_manage_your_licenses')}
      />
    </>
  );
}

export default EmptyLicenses;
